import { SendMailParams } from "./models/mail";
import { environment as env } from '../environments/environment';

export const productionTitle = 'Ann Lee'
const crewtimeLink = 'https://annlee.crewtime.app'
const financeEmail = 'annlee.finance@protoncinema.hu'
const pmEmail = 'andrew@mauifilms.hu' // Viz András
export const invoiceMainualLink = 'https://docs.google.com/document/d/16fxzPt-PFGhmJvuTIxq3E7j-HalMoPJV/edit?usp=sharing&ouid=107918629467201434763&rtpof=true&sd=true'

export const config = {
    SHOW_GROWING_OT_RATE: false,
    CONFIG_PERSONAL_END_DATES: true,
    HAS_FOREIGN_CREW: false,

    DISABLE_OLD_TS: false,
    DISABLE_OLD_TS_OT: false,
    ALLOW_CREW_MEMBER_EDIT_FEES: true,
    ALLOW_CREW_MEMBER_EDIT_OT_STEP: false,
    ALLOW_CREW_MEMBER_EDIT_TA_FEE: true,
    ALLOW_CREW_MEMBER_EDIT_CONTRACT_DATES: true,
    ALLLOW_CONTRACT_END_DATE: true,
    ALLOW_PERDIEM: true,
    ALLOW_OT_PERCENT: true,
    OT_PERCENT: 10,
    
    /** 'fixed-rates': Nem adható meg naponta új árfolyam. Ha több mint 2 pénznem van, akkor csak ez választható, mert a napi árfolyam mentésekor nem adható meg, hogy melyik pénznam árfolyamát mented */
    MULTIPLE_CURRENCIES: 'fixed-rates' as 'fixed-rates' | boolean,

    DISABLED_TS_HISTORY_TEXT: 'Letiltva',
    
    HU: {
        CONTRACT: {
            ENABLE_FILTER_FINANCE_APPROVED: true,
            SEND_MAIL_FOR_SENDBACK_TO_CREW: {
                ccTo: pmEmail,
                subject: `[${productionTitle}] Start Form visszaküldve`,
                body: `Kedves {USERNAME}!<br><br>A Start Formodat visszaküldték javításra. Kérlek, javítsd ki a hibákat, és küldd be újra a ${crewtimeLink} címen.`,
                bodyEnding: '<br><br>Köszönjük!<br>CrewTime',
            },
            SEND_MAIL_FOR_APPROVED: {
                enabled: false,
                sendTo: financeEmail, // vg:'frederiksberg2021@gmail.com',
                subject: `[${productionTitle}] Start Form elfogadva`,
                body: `Sziasztok!<br><br>A következő Start Formot jóváhagyták: {START_FORM}. Megtekintheted a ${crewtimeLink} címen.<br><br>Üdvözlettel!<br>CrewTime`,
            },
            SEND_MAIL_FINANCE_NOTIF_NEW_POSITION: {
                enabled: false,
                sendTo: financeEmail,
                subject: `[${productionTitle}] Új pozíció jóváhagyva`,
                body: `Sziasztok! <br><br> A következő Start Formot új pozícióval, de megegyező céggel jóváhagyták: {START_FORM}. Megtekintheted a ${crewtimeLink} címen.<br><br>Üdvözlettel!<br>CrewTime`,
            }
        },
        USER: {
            SEND_MAIL_FOR_REG_ACCEPTED: {
                subject: `[${productionTitle}] Regisztráció elfogadva`,
                body: `Kedves {USERNAME}!<br><br>A regisztrációdat elfogadtuk. Kérlek, töltsd ki a start formodat a ${crewtimeLink} címen.<br><br>Köszönjük!<br>CrewTime`,
            },
        },
        TS: {
            SEND_MAIL_FOR_SENDBACK_TO_CREW: {
                ccTo: pmEmail,
                subject: `[${productionTitle}] Timesheet visszaküldve`,
                body: `Kedves {USERNAME}!<br><br>A Timesheetedet visszaküldték javításra. Kérlek, javítsd ki a hibákat, és küldd be újra a ${crewtimeLink} címen.`,
                bodyEnding: '<br><br>Köszönjük!<br>CrewTime',
            },
            MEAL_PENALTY: 'firstValueableOtRate' as 'firstValueableOtRate' | 'setAtProdDeptSf' | false,
            DISPLAY_KM_PARKING_VIGN_IN_CALCULATED_SECTION: true,
        },
    },
} as const