<form (submit)="reload()" [formGroup]="form">
    <div class="controls">
        <app-filter-date-range [formgroup]="form" [fromFC]="fromFC" [toFC]="toFC" [min]="minDate" [max]="maxDate"
            class="form-field">
        </app-filter-date-range>
    </div>
    <div class="buttons form-field">
    <button mat-raised-button type="submit" 
        [disabled]="canGeneratePdf().disabled"
        [title]="canGeneratePdf().title"
        class="btnGenerate">
        {{ 'view-wts' | t7e:'WTS megtekintése' }}
    </button>
    </div>
</form>

<div class="loading">
    <mat-spinner *ngIf="isDataLoading" [diameter]="50"></mat-spinner>
</div>

<div [title]="'inv-attachment' | t7e:'Számlakép'" *ngIf="draft$ | async; else noData" (close)="closeDraft()" class="draft-window my-wts">
    <div [innerHTML]="(draft$ | async)" class="draft-window-content"></div>
</div>

<ng-template #noData>
    <div class="no-data" *ngIf="isDataLoaded">
        {{ 'no-data' | t7e:'Nincs adat' }}
    </div>
</ng-template>