export const dictionary = {
    "__componentName__": {
        "__objectName__": {
            "__attrName__": {
                "hu": "ide_írd_a_magyar_fordítást",
                "en": "ide_az_angolt"
            },
        }
    },
    "__componentNameWithVariable__": {
        "__objectName__": {
            "__attrName__": {
                "hu": "ide_írd_a_magyar_fordítást_{0}_és_{1}",
                "en": "ide_az_{0}_angolt_{1}"
            },
        }
    },

    "app-root": {
        "login gomb": {
            "innerText": {
                "hu": "Belépés",
                "en": "Login"
            },
            "title": {
                "hu": "Az app használatához be kell jelentkezned",
                "en": "Log in to use the app"
            },
        },

    },

    "app-manage-timesheets": {
        "tab-label-table-view": {
            "innerText": {
                "en": "Table view",
                "hu": "Táblázatos nézet",
                "descr": null
            }
        },
        "tab-label-page-view": {
            "innerText": {
                "en": "Page view",
                "hu": "Teljes nézet",
                "descr": null
            }
        }
    },

    "app-timesheets": {
        "pop-salary-reason-title": {
            "innerText": {
                "en": "Explanation",
                "hu": "Hogy jött ki?",
                "descr": "pop-salary-reason-title"
            }
        },
        "no-record": {
            "innerText": {
                "en": "No Timesheet found for the selected filters",
                "hu": "Nincs a szűrésnek megfelelő Timesheet",
            }
        },
        "not-loaded": {
            "innerText": {
                "en": "Set filters and click Load",
                "hu": "Válassz szűrőket és kattints a Betöltés gombra",
            }
        },
        "confirm": {
            "delete-title": {
                "en": "Are you sure you want to delete this Timesheet?",
                "hu": "Biztos törlöd?",
            },
            "btn-delete": {
                "en": "Delete",
                "hu": "Törlés",
            },
            "send-back-title": {
                "en": "Are you sure you want to send this Timesheet back? Why?",
                "hu": "Valóban visszaküldöd? Miért?",
            },
            "btn-send-back": {
                "en": "Send back",
                "hu": "Visszaküldés",
            }
        },
        "override-daily-rate-hint": {
            "innerText": {
                "en": "You can override the calculated daily rate",
                "hu": "Felülírhatod a kalkulált napidíjat",
            }
        },
        "notif": {
            "error-no-response-data": {
                "en": "Error: No response data",
                "hu": "A mentés nem adott vissza eredményt",
            },
            "save-error": {
                "en": "Error saving Timesheet",
                "hu": "Hiba történt a mentés közben",
            },
            "sending": {
                "en": "Submitting...",
                "hu": "Beküldés folyamatban...",
            },
            "submit-success": {
                "en": "Timesheet submitted successfully",
                "hu": "Sikeres beküldés",
            },
            "submit-error": {
                "en": "Error submitting Timesheet",
                "hu": "Hiba történt a beküldés közben",
            },
            "approving": {
                "en": "Approving...",
                "hu": "Jóváhagyás folyamatban...",
            },
            "approve-success": {
                "en": "Timesheet approved successfully",
                "hu": "Sikeres jóváhagyás",
            },
            "approve-error": {
                "en": "Error approving Timesheet",
                "hu": "Hiba történt a jóváhagyás közben",
            }
        },
        "you-are-not-prod-mgr": {
            "innerText": {
                "en": "You are not a production manager",
                "hu": "Nem vagy gyártásvezető",
            }
        },
        "dayoptions": {
            "travel-day": {
                "en": "Travel day",
                "hu": "Utazó nap",
            },
            "half-day": {
                "en": "Half day",
                "hu": "Fél nap",
            },
            "monthly-rents-due": {
                "en": "Monthly rents due",
                "hu": "Havi bérleti díjak esedékesek",
            },
        },
        "km-fee": {
            "innerText": {
                "en": "Km fee",
                "hu": "Km díj",
                "descr": "km-fee"
            }
        },
        "parking": {
            "innerText": {
                "en": "Parking",
                "hu": "Parkolás",
                "descr": "parking"
            }
        },
        "vignette": {
            "innerText": {
                "en": "Vignette",
                "hu": "Matrica",
                "descr": "vignette"
            }
        },
        "otherfee": {
            "innerText": {
                "en": "Other fee",
                "hu": "Egyéb díj",
                "descr": "otherfee"
            }
        },
        "pd-travel-halfday-questionmark": {
            "innerText": {
                "en": "Day type",
                "hu": "Nap jellemzői",
                "descr": "from pipe: pd-etc-title"
            }
        }
    },

    "app-grand-otta": {
        "title-h1": {
            "innerText": {
                "hu": "OT&TA Grand Total",
                "descr": "title-h1",
                "en": "OT&TA Grand Total"
            }
        },
        "col2_header": {
            "innerText": {
                "en": "Requested",
                "hu": "Igényelt",
                "descr": "col2_header"
            }
        },
        "col3_header": {
            "innerText": {
                "en": "Spent",
                "hu": "Elköltött",
                "descr": "col3_header"
            }
        },
        "col4_header": {
            "innerText": {
                "en": "Spent %",
                "hu": "Elköltött %",
                "descr": "col4_header"
            }
        },
        "col1_header": {
            "innerText": {
                "en": "OT + TA TOTAL GL Budget",
                "hu": "OT + TA TOTAL GL Keret",
                "descr": "col1_header"
            }
        },
        "requested_ot": {
            "innerText": {
                "en": "Requested OT Budget",
                "hu": "Igényelt OT költség",
                "descr": "requested_ot"
            }
        },
        "requested_ta": {
            "innerText": {
                "en": "Requested TA Budget",
                "hu": "Igényelt TA költség",
                "descr": "requested_ta"
            }
        },
        "requested_total": {
            "innerText": {
                "en": "Total Requested (OT + TA)",
                "hu": "Összes igényelt (OT + TA)",
            }
        },
        "approved_ot": {
            "innerText": {
                "en": "Total approved OT",
                "hu": "Jóváhagyott OT költség",
                "descr": "approved_ot"
            }
        },
        "approved_ta": {
            "innerText": {
                "en": "Total approved TA",
                "hu": "Jóváhagyott TA költség",
                "descr": "approved_ta"
            }
        },
        "accepted_total": {
            "innerText": {
                "en": "TOTAL APPROVED OT + TA",
                "hu": "Összes jóváhagyott OT + TA",
                "descr": "accepted_total"
            }
        }
    },
    
    "app-users": {
        "contract-fee": {
            "innerText": {
                "hu": "",
                "en": "",
                skipWarnMissing: true,
            }
        },
        "name": {
            "innerText": {
                "hu": "Név",
                "en": "Name"
            }
        },
        "jwt tokennel védett hívás gomb felirat": {
            "title": {
                "hu": "Védve vagy",
                "en": "Youre protected"
            }
        },

        "page-title": {
            "innerText": {
                "hu": "Felhasználók kezelése",
                "en": "Manage Users"
            }
        },
        "status": {
            "active": {
                "hu": "Aktív",
                "en": "Active"
            },
            "inactive": {
                "hu": "Minden inaktív",
                "en": "All inactive"
            },
            "pending": {
                "hu": "Jóváhagyásra vár",
                "en": "Pending"
            }
        },
        "notif": {
            "error-loading-data": {
                "hu": "Hiba történt a user lista betöltése közben",
                "en": "Error loading user list"
            },
            "error-saving-data": {
                "hu": "Hiba történt a user mentése közben",
                "en": "Error saving user"
            },
            "saving": {
                "hu": "Mentés...",
                "en": "Saving..."
            },
            "saved": {
                "hu": "Elmentve",
                "en": "Saved"
            }
        },
        "confirm": {
            "delete": {
                "hu": "Biztosan törölni akarod?",
                "en": "Are you sure you want to delete her/him?"
            }
        },
    },

    "app-weekly-otta": {
        "page-title": {
            "innerText": {
                "hu": "Heti OT & TA",
                "descr": null,
                "en": "Weekly OT & TA"
            }
        },
        "col.week": {
            "title": {
                "hu": "Hét",
                "descr": null,
                "en": "Week"
            }
        },
        "col.otta": {
            "title": {
                "hu": "OT + TA",
                "descr": null,
                "en": "OT + TA"
            }
        },
        "col.day": {
            "title": {
                "hu": "Nap",
                "descr": null,
                "en": "Day"
            }
        },
        "col.daily-otta": {
            "title": {
                "hu": "Napi OT + TA",
                "descr": null,
                "en": "Daily OT + TA"
            }
        }
    },

    "app-prod-defaults": {
        "locations": {
            "innerText": {
                "hu": "Helyszínek",
                "en": "Locations"
            }
        },
        "holidays": {
            "innerText": {
                "hu": "Ünnepnapok",
                "en": "Holidays"
            }
        },
        "save": {
            "innerText": {
                "hu": "Mentés",
                "en": "Save"
            }

        },
        "currency-exchange-rates": {
            "innerText": {
                "hu": "Árfolyamok",
                "en": "Currency exchange rates"
            }
        },
        "minute": {
            "innerText": {
                "hu": " perc",
                "en": " minute"
            }
        }
    },
    "app-dashboard": {
        "box-my-wts": {
            "innerText": {
                "hu": "HETI TIMESHEET",
                "descr": "box-my-wts",
                "en": null
            }
        },
        "box-ts-summary-title": {
            "innerText": {
                "hu": "EKHO",
                "descr": "box-ts-summary-title",
                "en": "Timesheet summary"
            }
        },
        "page-title": {
            "innerText": {
                "hu": "",
                "en": "",
                skipWarnMissing: true,
            }
        },
        "box-shootingdays-title": {
            "innerText": {
                "hu": "FORGATÁSI NAPOK",
                "en": "SHOOTING DAYS"
            }
        },
        "box-wts-title": {
            "innerText": {
                "hu": "Heti Timesheetek",
                "en": "Weekly Timesheets"
            }
        },
        "box-weekly-otta-title": {
            "innerText": {
                "hu": "Heti OT & TA",
                "en": "Weekly OT & TA"
            }
        },
        "box-salary-report-title": {
            "innerText": {
                "hu": "Napidíjak",
                "en": "Salary report"
            }
        },
        "pending-sending": {
            "innerText": {
                "hu": "WTS kiküldésre vár",
                "en": "pending WTS"
            }
        },
        "awaiting-signature": {
            "innerText": {
                "hu": "aláírásra vár",
                "en": "awaiting signature"
            }
        },
        "approved": {
            "innerText": {
                "hu": "jóváhagyva",
                "en": "approved"
            }
        },
        "crew-members": {
            "innerText": {
                "hu": "stábtag",
                "en": "crew members"
            }
        },
        "pending": {
            "innerText": {
                "hu": "várakozik",
                "en": "pending"
            }
        },
        "pending-approval": {
            "innerText": {
                "hu": "vár jóváhagyásra",
                "en": "pending approval"
            }
        },
        "dept-members": {
            "innerText": {
                "hu": "tag",
                "descr": "dept-members",
                "en": "department members."
            }
        },
        "basic-user-data-body1": {
            "innerText": {
                "hu": "A CrewTime app egy online alkalmazás, amely segítségével a stábtagok könnyedén kezelhetik a start formokat, a szerződést és a timesheeteket.",
                "descr": "basic-user-data-body1",
                "en": "CrewTime app is an online application that helps crew members to easily manage start forms, the contract and timesheets."
            }
        },
        "basic-user-data-body2": {
            "innerText": {
                "hu": "Kezdetnek adj meg néhány adatot, hogy a gyártásvezető jóvá tudja hagyni a regisztrációdat!",
                "descr": "basic-user-data-body2",
                "en": "To start, enter some data so that the production manager can approve your registration!"
            }
        },
        "title-h1": {
            "innerText": {
                "hu": "Főoldal",
                "descr": "title-h1",
                "en": "Dashboard"
            }
        },
        "basic-user-data-title": {
            "innerText": {
                "en": "Welcome to ",
                "hu": "Üdvözöl a ",
                "descr": "Az üdvözlő, alap adatokat bekérő doboz címe"
            }
        },

        "congrats-you-are-in": {
            "title": {
                "hu": "Gratulálunk! Beléptél, de...",
                "descr": "congrats-you-are-in-title",
                "en": "Congrats! You are in! But..."
            },
            "description": {
                "hu": "Még nem hagytak jóvá mint stábtagot. Kérlek vedd fel a kapcsolatot a gyártásvezetővel, hogy engedélyezze a fiókod.",
                "descr": "congrats-you-are-in-descr",
                "en": "You are not yet approved as a crew member. Please contact the production manager to enable your account."
            },
            "innerText": {
                "hu": "Főoldal",
                "descr": "title-h1",
                "en": "Dashboard"
            }

        },
        "box-users-title": {
            "innerText": {
                "hu": "Felhasználók",
                "en": "Crew Members",
            }
        },
        "box-startforms-title": {
            "innerText": {
                "hu": "Start formok",
                "en": "Start Forms",
            }
        },
        "box-contracts-title": {
            "innerText": {
                "hu": "Szerződések",
                "en": "Contracts",
            }
        },
        "box-timesheets-title": {
            "innerText": {
                "hu": "Timesheetek",
                "en": "Timesheets",
            }
        },
        "box-profile-complete-your-profile": {
            "innerText": {
                "hu": "Hiányzó személyes adatok!",
                "descr": "box-profile-complete-your-profile",
                "en": "Missing profile data!"
            }
        },
        "box-contracts-at-crew": {
            "title": {
                "hu": "Beküldésre vár start formod",
                "descr": "box-contracts-at-crew",
                "en": "Start Form(s) awaiting your submission"
            }
        },
        "box-ts-at-crew": {
            "title": {
                "hu": "Beküldésre vár timesheeted",
                "descr": "box-ts-at-crew",
                "en": "Timesheets awaiting your submission"
            }
        },
        "you-have": {
            "innerText": {
                "hu": " ",
                "descr": "you have",
                "en": "You have "
            }
        },
        'you-have-x-contracts': {
            "innerText": {
                "hu": " start formod van",
                "descr": "you have x start forms",
                "en": " start forms"
            }
        },

        "section-your-data": {
            "innerText": {
                "hu": "",
                "descr": "section-your-data",
                "en": null
            },
            "title": {
                "hu": "Saját adatok",
                "descr": "section-your-data-title",
                "en": "Your data"
            }
        },
        "section-dept-data": {
            "innerText": {
                "hu": "",
                "en": "",
                skipWarnMissing: true,
            },
            "title": {
                "hu": "Részleg admin",
                "descr": "section-your-data-title",
                "en": "Department Admin"
            }
        },
        "section-production": {
            "innerText": {
                "hu": "",
                "en": ""
            },
            "title": {
                "hu": "Produkció",
                "en": "Production"
            }
        },
        "section-finance": {
            "innerText": {
                "hu": "",
                "en": ""
            },
            "title": {
                "hu": "Pénzügy",
                "descr": "section-your-data-title",
                "en": "Finance"
            }
        },
        "section-reports": {
            "innerText": {
                "hu": "",
                "descr": "section-your-data",
                "en": null
            },
            "title": {
                "hu": "Jelentések",
                "descr": "section-your-data-title",
                "en": "Reports"
            }
        },



        "box-profile": {
            "innerText": {
                "hu": "PROFILOM",
                "descr": "box-profile",
                "en": "PROFILE"
            }
        },

        "box-my-contracts": {
            "innerText": {
                "hu": "START FORMJAIM",
                "descr": "box-my-contracts",
                "en": "MY START FORMS"
            }
        },
        "box-my-ts": {
            "innerText": {
                "hu": "SAJÁT TIMESHEETEK",
                "descr": "box-my-ts",
                "en": "MY TIMESHEETS"
            }
        },
        "sent": {
            "innerText": {
                "hu": "beküldve",
                "descr": "sent",
                "en": "sent"
            }
        },
        "awaiting-invoice": {
            "innerText": {
                "hu": "számlázásra vár",
                "descr": "awaiting-invoice",
                "en": "awaiting your invoice"
            }
        },
        "box-crew-list": {
            "innerText": {
                "hu": "STÁBLISTA",
                "descr": "box-crew-list",
                "en": "CREW LIST"
            }
        },
        "box-crew-list-crewmembers": {
            "innerText": {
                "hu": "stáb tag",
                "descr": "box-crew-list-crewmembers",
                "en": "crew members"
            }
        },



        "box-info-inv-title": {
            "innerText": {
                "hu": "SZÁMLÁZÁSI INFORMÁCIÓK",
                "descr": "box-info-inv-title",
                "en": "INVOICING INFORMATION"
            }
        },
        "box-about": {
            "innerText": {
                "hu": "A CREWTIME APP",
                "descr": "box-about",
                "en": "CREWTIME APP"
            }
        },

        "version": {
            "innerText": {
                "en": "Version",
                "hu": "Verzió",
                "descr": "version"
            }
        },
    },

    "app-dept-ts": {
        "select-ts": {
            "innerText": {
                "hu": "Válassz Timesheetet",
                "en": "Select Timesheet"
            }
        },
        "dept": {
            "innerText": {
                "hu": "Részleg Timesheetjei",
                "en": "Department Timesheets"
            }
        },
        "new-ts-button": {
            "innerText": {
                "hu": "Új TS",
                "en": "New TS"
            }
        },
        "no-filters-selected": {
            "innerText": {
                "hu": "Válassz napot és stábtagot!",
                "en": "Select a date and a crew member!"
            }
        },
        "btn-refresh-text": {
            "innerText": {
                "hu": "Frissít",
                "en": "Reload"
            },
        },
    },

    "app-save-button": {
        "button-text": {
            "innerText": {
                "hu": "Mentés",
                "descr": "button-text",
                "en": "Save"
            }
        },
        "error-text": {
            "innerText": {
                "hu": "Hiba a mentés során",
                "descr": "error-text",
                "en": "Error saving data"
            }
        },
    },


    "app-profile": {
        "h1_001": {
            "innerText": {
                "hu": "Személyes adatok",
                "descr": "h1_001",
                "en": "Personal data"
            }
        },
        "s_001": {
            "innerText": {
                "hu": "Regisztráció elfogadva mint ",
                "descr": "s_001",
                "en": "Registration accepted as a "
            }
        },
        "mh_001": {
            "innerText": {
                "hu": "Az email cím nem módosítható",
                "descr": "mh_001",
                "en": "Email address cannot be changed"
            }
        },
        "ml_002": {
            "innerText": {
                "hu": "Keresztnév",
                "descr": "ml_002",
                "en": "Last Name"
            }
        },
        "ml_002_2": {
            "innerText": {
                "hu": "Vezetéknév",
                "descr": "ml_002",
                "en": "Family Name"
            }
        },
        "ml_003": {
            "innerText": {
                "hu": "Telefonszám",
                "descr": "ml_003",
                "en": "Phone nr"
            }
        },
        "ml_001": {
            "innerText": {
                "hu": "Email",
                "descr": "ml_001",
                "en": "Email",
            }
        },
        "name-hint": {
            "innerText": {
                "hu": "Személyi igazolványban szereplő név",
                "descr": "name-hint",
                "en": "Your name in your ID.",
            }
        },
        "tel-hint": {
            "innerText": {
                "hu": "Pl +36 30 123 1234",
                "descr": "tel-hint",
                "en": "eg +36 43 123 1234",
            }
        },
        "app-save-button": {
            "errorText": {
                "hu": "A mentés nem sikerült!",
                "descr": "A mentés nem sikerült!",
                "en": "Save failed!"
            },
            "successText": {
                "hu": "A mentés sikerült! ",
                "descr": "A mentés sikerült! ",
                "en": "Save successful! "
            },
            "innerText": {
                "hu": "",
                "descr": "savebutton",
                "en": "",
            }
        },
        "you have no usergroup": {
            "innerText": {
                "hu": "Még nem fogadta el a gyártásvezető a regisztrációdat",
                "descr": "you have no usergroup",
                "en": "Your production manager has not approved your registration yet"
            }
        },

    },

    "app-my-contract": {
        "my-contracts": {
            "innerText": {
                "en": "My Start Forms",
                "hu": "Saját Start Formjaim",
                "descr": "my-contracts"
            }
        },
        "loading-contracts": {
            "innerText": {
                "en": "Loading contracts...",
                "hu": "Start formok betöltése...",
                "descr": "loading-contracts"
            }
        },
        "no-contract-warning": {
            "title": {
                "en": "You have no start form yet",
                "hu": "Még nincs start formod",
                "descr": "no-contract-warning"
            },
            "description": {
                "en": "Create one now!",
                "hu": "Hozz létre egyet most!",
                "descr": "no-contract-warning"
            }
        },
    },


    "app-days": {
        "shootingDays": {
            "innerText": {
                "hu": "Forgatási napok",
                "en": "Shooting days"
            }
        }
    },


    "app-manage-contracts": {
        "button-open-otta-bulk-changer": {
            "title": {
                "hu": "A kijelöltek OT-ját és TA-ját állíthatod át ezzel a gombbal",
                "descr": null,
                "en": null
            },
        },
        "address": {
            "innerText": {
                "hu": "Lakcím",
                "en": "Address"
            }
        },
        "mothers-name": {
            "innerText": {
                "hu": "Anyja neve",
                "en": "Mother's name"
            }
        },
        "birth-place": {
            "innerText": {
                "hu": "Születési hely, idő",
                "en": "Birth place, date"
            }
        },
        "tax-id": {
            "innerText": {
                "hu": "Adóazonosító jel",
                "en": "Tax ID"
            }
        },
        "taj": {
            "innerText": {
                "hu": "TAJ szám",
                "en": "TAJ number"
            }
        },
        "bank-account": {
            "innerText": {
                "hu": "Bankszámlaszám",
                "en": "Bank account"
            }
        },
        "save-comment": {
            "send-back-to-crew-ok-button": {
                "hu": "Visszaküldöm",
                "en": "Send back"
            }
        },
        "history": {
            "innerText": {
                "hu": "Előzmények",
                "en": "History"
            }
        },
        "filter": {
            "contract-date-approved-label": {
                "hu": "Jóváhagyás ezóta",
                "en": "Approval since"
            },
        },
        "uploaded-files": {
            "innerText": {
                "hu": "Google Drive mappa",
                "en": "Google Drive folder"            }
        },
        "effective-title": {
            "innerText": {
                "hu": "Érvényes érték, a részleg alapértéke és a produkció alapértéke alapján",
                "en": "Effective value, inherited from department default and producion default"
            }
        },
        "no-records": {
            "innerText": {
                "hu": "Nincs megjeleníthető adat.",
                "descr": "no-records",
                "en": "No start form to display."
            }
        },
        "btn-approve": {
            "innerText": {
                "en": "Approve",
                "hu": "Jóvágagyás",
                "descr": "btn-approve"
            }
        },
        "btn-retract-approval": {
            "innerText": {
                "en": "Retract Prod Mgr approval",
                "hu": "Gyv jóváhagyás visszavonása",
            }
        },
        "btn-send-back-to-crew": {
            "innerText": {
                "en": "Send back to crew member",
                "hu": "Visszaküldés stábtagnak",
                "descr": "btn-send-back-to-crew"
            }
        },
        "btn-send-back-to-prodmgr": {
            "innerText": {
                "en": "Send back to ProdMgr.",
                "hu": "Visszaküldés gyártásvezetőnek",
                "descr": "btn-send-back-to-prodmgr"
            }
        },
        "btn-set-approved": {
            "innerText": {
                "en": "Set: Approved",
                "hu": "Új státusz: Jóváhagyva",
                "descr": "btn-set-approved"
            }
        },
        "btn-set-readytosign": {
            "innerText": {
                "en": "Set: Ready to sign",
                "hu": "Új státusz: Aláírásra kész",
                "descr": "btn-set-readytosign"
            }
        },
        "btn-set-signed": {
            "innerText": {
                "en": "Set: Signed",
                "hu": "Új státusz: Aláírva",
                "descr": "btn-set-signed"
            }
        },
        "btn-export-excel": {
            "innerText": {
                "en": "Export to Excel",
                "hu": "Exportálás Excelbe",
                "descr": "btn-export-excel"
            }
        },
        "page-title": {
            "innerText": {
                "en": "Manage Start Forms",
                "hu": "Start Formok",
            }
        },
        "contract-fee": {
            "innerText": {
                "en": "Contract fee",
                "hu": "Szerződéses díj",
                "descr": "contract-fee"
            }
        },
        "preprod-rate": {
            "innerText": {
                "en": "Daily rate (preprod.)",
                "hu": "Napidíj (előkészítés)",
                "descr": "preprod-rate"
            }
        },
        "shooting-rate": {
            "innerText": {
                "en": "Daily rate (shooting)",
                "hu": "Napidíj (forgatás)",
                "descr": "shooting-rate"
            }
        },
        "perdiem-fee": {
            "innerText": {
                "en": "Per Diem fee",
                "hu": "Per Diem díj",
                "descr": "perdiem-fee"
            }
        },
        "ot-rate": {
            "innerText": {
                "en": "OT rate",
                "hu": "Túlóradíj",
                "descr": "ot-rate"
            }
        },
        "ot-step": {
            "innerText": {
                "en": "OT step",
                "hu": "OT lépcső [óra]",
                "descr": "ot-step"
            }
        },
        "ta-short": {
            "innerText": {
                "en": "Short rest time [hour]",
                "hu": "Rövid pihenőidő [óra]",
                "descr": "ta-short"
            }
        },
        "ta-long": {
            "innerText": {
                "en": "Long rest time [hour]",
                "hu": "Hosszú pihenőidő [óra]",
                "descr": "ta-long"
            }
        },
        "ta-rate": {
            "innerText": {
                "en": "TA rate",
                "hu": "TA óradíj",
                "descr": "ta-rate"
            }
        },
        "contract-start-date": {
            "innerText": {
                "en": "Contract start date",
                "hu": "Első munkanapod dátuma",
                "descr": "contract-start-date"
            }
        },
        "contract-end-date": {
            "innerText": {
                "en": "Contract end date",
                "hu": "Várható végzés időpontja",
                "descr": "contract-end-date"
            }
        },
        "licenseplate-nr": {
            "innerText": {
                "en": "License plate nr",
                "hu": "Rendszám",
                "descr": "licenseplate-nr"
            }
        },
        "code-daily-rate": {
            "innerText": {
                "en": "Code: Daily rate",
                "hu": "Kód: Napidíj",
                "descr": "code-daily-rate"
            }
        },
        "code-otta": {
            "innerText": {
                "en": "Code: OT&TA",
                "hu": "Kód: OT&TA",
                "descr": "code-otta"
            }
        },
        "code-km": {
            "innerText": {
                "en": "Code: Fuel",
                "hu": "Kód: Üzemenyag",
                "descr": "code-km"
            }
        },
        "code-park": {
            "innerText": {
                "en": "Code: Parking",
                "hu": "Kód: Parkolás",
                "descr": "code-park"
            }
        },
        "code-vignette": {
            "innerText": {
                "en": "Code: Vignette",
                "hu": "Kód: Matrica",
                "descr": "code-vignette"
            }
        },
        "code-perdiem": {
            "innerText": {
                "en": "Code: Per Diem",
                "hu": "Kód: Per Diem",
                "descr": "code-perdiem"
            }
        },
        "code-other": {
            "innerText": {
                "en": "Code: Other",
                "hu": "Kód: Egyéb",
                "descr": "code-other"
            }
        },
        "notif": {
            "error-loading-data": {
                "en": "Error loading Timesheets",
                "hu": "Hiba történt a Timesheet lista betöltése közben",
            },
            "error-saving-data": {
                "en": "Error saving Timesheet",
                "hu": "Hiba történt a Timesheet mentése közben",
            },
            "saving": {
                "en": "Saving...",
                "hu": "Mentés...",
            },
            "saved": {
                "en": "Saved",
                "hu": "Elmentve",
            },
            "ok-set-approved-selected": {
                "en": "Set selected start forms to approved",
                "hu": "Kiválasztott start formok jóváhagyott státuszba helyezése",
            },
            "nok-set-approved-selected": {
                "en": "Not all selected start forms can be set to approved",
                "hu": "Nem minden kiválasztott start form tehető jóváhagyott státuszba",
            },
            "ok-set-readytosign-selected": {
                "en": "Set selected start forms to \"Ready to sign\" status",
                "hu": "Kiválasztott start formok \"Aláírásra kész\" státuszba helyezése",
            },
            "nok-set-readytosign-selected": {
                "en": "Not all selected start forms can be set to \"Ready to sign\" status",
                "hu": "Nem minden kiválasztott start formra állítható be a \"Aláírásra kész\" státusz",
            },
            "ok-set-signed-selected": {
                "en": "Set selected start forms to \"Signed\" status",
                "hu": "Kiválasztott start formok \"Aláírt\" státuszba helyezése",
            },
            "nok-set-signed-selected": {
                "en": "Not all selected start forms can be set to \"Signed\" status",
                "hu": "Nem minden kiválasztott start formra állítható be a \"Aláírt\" státusz",
            },
            "ok-return-to-crew": {
                "en": "Return selected start forms to crew member",
                "hu": "Kiválasztott start formok visszaküldése a stábtagnak",
            },
            "nok-return-to-crew": {
                "en": "Not all selected start forms can be returned to crew member",
                "hu": "Nem minden kiválasztott start form visszaküldhető a stábtagnak",
            },
            "ok-return-to-prodmgr": {
                "en": "Return selected start forms to production manager",
                "hu": "Kiválasztott start formok visszaküldése a gyártásvezetőnek",
            },
            "nok-return-to-prodmgr": {
                "en": "Not all selected start forms can be returned to production manager",
                "hu": "Nem minden kiválasztott start form visszaküldhető a gyártásvezetőnek",
            },
            "ok-set-disabled-selected": {
                "en": "Disable selected start forms",
                "hu": "Kiválasztott start formok inaktiválása",
            },
            "nok-set-disabled-selected": {
                "en": "Not all selected start forms can be disabled",
                "hu": "Nem minden kiválasztott start form inaktiválható",
            },
            "ok-set-deleted-selected": {
                "en": "Delete selected start forms",
                "hu": "Kiválasztott start formok törlése",
            },
            "nok-set-deleted-selected": {
                "en": "Not all selected start forms can be deleted",
                "hu": "Nem minden kiválasztott start form törölhető",
            },
            "none-selected": {
                "en": "No start forms selected",
                "hu": "Nincs kiválasztva egy start form sem",
            }
        },
    },

    "kendo-grid-toolbar": {
        "button-open-otta-bulk-changer": {
            "innerText": {
                "hu": "OT/TA megadása...",
                "en": "Set OT/TA rate..."
            }
        },
        "btn-approve": {
            "innerText": {
                "hu": "Jóváhagyás",
                "en": "Approve"
            }
        },
        "btn-set-approved": {
            "innerText": {
                "hu": "Új státusz: Jóváhagyva",
                "en": "Set: Approved"
            }
        },
        "btn-set-readytosign": {
            "innerText": {
                "hu": "Új státusz: Aláírásra kész",
                "en": "Set: Ready to sign"
            }
        },
        "btn-set-signed": {
            "innerText": {
                "hu": "Új státusz: Aláírva",
                "en": "Set: Signed"
            }
        },
        "btn-send-back-to-crew": {
            "innerText": {
                "hu": "Visszaküldés stábtagnak",
                "descr": "btn-send-back-to-crew",
                "en": "Send back"
            }
        },
        "btn-export-excel": {
            "innerText": {
                "en": "Export to Excel",
                "hu": "Exportálás Excelbe",
                "descr": "btn-export-excel"
            }
        },
        "btn-generate": {
            "innerText": {
                "en": "Generate contract",
                "hu": "Szerződés generálás",
                "descr": "btn-generate"
            }
        },
        "btn-generate-and-send": {
            "innerText": {
                "en": "Generate and send as attachment",
                "hu": "Generálás és küldés emailben",
                "descr": "btn-generate-and-send"
            }
        },
        "btn-retract-approval": {
            "innerText": {
                "en": "Retract Prod Mgr approval",
                "hu": "Gyv jóváhagyás visszavonása",
            }
        },
        "btn-send-back-to-prodmgr": {
            "innerText": {
                "en": "Send back to ProdMgr.",
                "hu": "Visszaküldés gyártásvezetőnek",
                "descr": "btn-send-back-to-prodmgr"
            }
        },

    },

    "app-gen-contract-file": {
        "folder": {
            "innerText": {
                "hu": "Mappa",
                "en": "Folder"
            }
        },
        "generate": {
            "innerText": {
                "en": "Generate",
                "hu": "Létrehozás",
                "descr": "generate"
            }
        },
        "no-generated-contract": {
            "innerText": {
                "en": "You have not generated a contract yet",
                "hu": "Még nem generáltál szerződést",
                "descr": "no-generated-contract"
            }
        },
        "generating-contract": {
            "innerText": {
                "en": "Generating contract...",
                "hu": "Szerződés generálása...",
                "descr": "generating-contract"
            }
        },
    },

    "app-filter-dept": {
        "label": {
            "innerText": {
                "hu": "Részleg",
                "en": "Department"
            }
        },
    },

    "app-filter-tsstatus": {
        "label": {
            "innerText": {
                "hu": "Státusz",
                "en": "Status"
            }
        },
    },

    "app-edit-contract": {
        "will-be-effective-when-saved": {
            "title": {
                "hu": "Jóváhagyáskor ez az érték kerül jóváhagyásra",
                "en": "This value will be saved at approval"
            }
        },
        "exchang-rate-loading": {
            "innerText": {
                "hu": "Árfolyam töltődik...",
                "en": "Loading exchange rate..."
            }
        },
        "duplicate-position-error": {
            "innerText": {
                "hu": "Nem lehet két start formban ugyanaz a pozíció. Ha ugyanaz a pozíciód, akkor is írj bele valami megkülönböztetést, amiben különbözik a többitől: pl ekho, cégnév, napidíj összege, stb. ",
                "en": "Cannot use the same position in two start forms. Even if the positions are the same, use some differentiator, like the Company name, the daily fee, etc."
            }
        },
        "notif": {
            "ok-return-to-crew": {
                "hu": "Kiválasztott start formok visszaküldése a stábtagnak",
                "en": "Reject selected start forms"
            }
        },
        "save-comment": {
            "send-back-to-crew-ok-button": {
                "hu": "Visszaküldöm",
                "en": "Reject"
            }
        },
        "history": {
            "innerText": {
                "hu": "Előzmények",
                "en": "History"
            }
        },
        "btn-finance-approve": {
            "title": {
                "hu": "Jóváhagyás (Pénzügy)",
                "en": "Finance Approve"
            }
        },
        "finance-approve-error": {
            "innerText": {
                "hu": "Nem sikerült jóváhagyni!",
                "en": "Failed to approve it"
            }
        },
        
    "delete-error": {
        "innerText": {
                "hu": "Nem sikerült törölni!",
                    "en": "Could not delete it"
        }
    },
        "btn-save-as-new": {
            "innerText": {
                "hu": "Start Form klónozása",
                "en": "Clone Start Form"
            },
            "title": {
                "hu": "Mentés új start formként",
                "en": "Save as new start form"
            }
        },
        "enddate-label": {
            "innerText": {
                "hu": "Várható befejezés dátuma",
                "descr": "enddate-label",
                "en": "Expected end date"
            }
        },
        "enddate-hint": {
            "innerText": {
                "hu": "Ez után a dátum után nem küldhetsz be timesheetet",
                "descr": "enddate-hint",
                "en": "You cannot submit a Timesheet for after this date"
            }
        },
        "code-perdiem-label": {
            "innerText": {
                "hu": "Kód: Per Diem",
                "descr": "code-perdiem-label",
                "en": "Code: Per Diem"
            }
        },
        "code-perdiem-hint": {
            "innerText": {
                "hu": "A Matrica könyvelési kódja",
                "descr": "code-perdiem-hint",
                "en": "The Per Diem accounting code"
            }
        },
        "can-save-no-permission": {
            "title": {
                "hu": "Nincs jogosultságod a szerkesztéshez",
                "en": "You have no permission to edit"
            }
        },
        "goto-my-ts": {
            "innerText": {
                "hu": "Tovább a timesheetekhez",
                "en": "Go to my timesheets"
            }
        },
        "startdate-hint": {
            "innerText": {
                "en": "You cannot submit a Timesheet for before this date",
                "hu": "Ez előtt a dátum előtt nem küldhetsz be timesheetet",
                "descr": "startdate-hint"
            }
        },
        "no-crew-warning": {
            "title": {
                "en": "Production manager has not approved you as a crew member yet",
                "hu": "A gyártásvezető még nem hagyott jóvá stábtagként",
            }
        },
        "not-editable-warning": {
            "title": {
                "en": "Start form already submitted",
                "hu": "A Start Formot beküldted, ezért az adataidat már nem szerkesztheted. Ha módosítani kívánsz, lépj kapcsolatba a gyártásvezetővel",
                "descr": "A Start Formot beküldted, ezért az adataidat már nem szerkesztheted. Ha módosítani kívánsz, lépj kapcsolatba a gyártásvezetővel"
            }
        },
        "saving-contract": {
            "innerText": {
                "en": "Saving start form...",
                "hu": "Start form mentése folyamatban...",
                "descr": "Start form mentése folyamatban..."
            }
        },
        "section-dept-and-role": {
            "title": {
                "en": "Department and Position",
                "hu": "Részleg és munkakör",
                "descr": "Részleg és munkakör"
            }
        },
        "dept": {
            "innerText": {
                "en": "Department",
                "hu": "Részleg",
                "descr": "dept"
            }
        },
        "dept-hint": {
            "innerText": {
                "en": "If you cannot find your department, please select Other",
                "hu": " Ha nem találod a részleged, akkor az Egyéb opciót válaszd ",
                "descr": "dept-hint"
            }
        },
        "role": {
            "innerText": {
                "hu": "Pozíció",
                "descr": "role",
                "en": "Position"
            }
        },
        "role-hint": {
            "innerText": {
                "hu": "A részlegen belüli feladatod. Pl: fővilágosító",
                "descr": "role-hint",
                "en": "Your position in the department. Eg: gaffer"
            }
        },
        "fees": {
            "title": {
                "hu": "Díjak",
                "descr": "Díjak",
                "en": "Fees"
            }
        },
        "required-field-error": {
            "innerText": {
                "hu": "Kötelező kitölteni",
                "descr": "required-field-error",
                "en": "Required field"
            }
        },

        "dept-default": {
            "innerText": {
                "en": "Department default",
                "hu": "Részleg alapértelmezett",
                "descr": "dept-default"
            }
        },
        "prod-default": {
            "innerText": {
                "en": "Production default",
                "hu": "Produkció alapértelmezett",
                "descr": "prod-default"
            }
        },
        "perdiem-label": {
            "innerText": {
                "en": "Per Diem fee",
                "hu": "Per Diem díj",
            }
        },
        "workhours-label": {
            "innerText": {
                "en": "Number of workhours",
                "hu": "Munkanap hossza",
            }
        },
        "perdiem-hint": {
            "innerText": {
                "en": "Extra fee for overnight days. Only if not ",
                "hu": "Az extra összeg, amely ottalvós napokon jár. Csak ha nem ",
            }
        },
        "hour-suffix": {
            "innerText": {
                "en": " hour",
                "hu": " óra",
            }
        },
        "day-suffix": {
            "innerText": {
                "en": " day",
                "hu": " nap",
            }
        },
        "only-if-not": {
            "innerText": {
                "en": "Only if not",
                "hu": "Csak ha nem",
            }
        },
        "section-ot": {
            "title": {
                "en": "Overtime (OT)",
                "hu": "Túlóra (OT)",
            }
        },
        "ot-rate-hint": {
            "innerText": {
                "en": "\"1000\" or \"1000 / 2000 / 3000\"",
                "hu": "\"1000\" vagy \"1000 / 2000 / 3000\"",
            }
        },
        "ot-rate-error": {
            "innerText": {
                "en": "Incorrect format",
                "hu": "Helytelen formátum",
            }
        },
        "ot-rate-error-growing-ot": {
            "innerText": {
                "en": "The correct format is: \"1000\" or \"1000 / 2000 / 3000\"",
                "hu": "A helyes formátum: \"1000\" vagy \"1000 / 2000 / 3000\"",
                "descr": "or-rate-error"
            }
        },
        "section-ta": {
            "title": {
                "en": "Turn Around (TA)",
                "hu": "Pihenőidő (TA)",
                "descr": "Pihenőidő (TA)"
            }
        },
        "ta-short-len-label": {
            "innerText": {
                "en": "Short TA length",
                "hu": "Rövid pihenőidő hossza",
                "descr": "ta-short-len-label"
            }
        },
        "ta-long-len-label": {
            "innerText": {
                "en": "Long TA length",
                "hu": "Hosszú pihenőidő hossza",
                "descr": "ta-long-len-label"
            }
        },
        "ta-rate-label": {
            "innerText": {
                "en": "TA rate per hour",
                "hu": "TA óradíj",
                "descr": "ta-rate-label"
            }
        },
        "section-fees": {
            "title": {
                "en": "Accountancy Codes",
                "hu": "Könyvelési kódok",
                "descr": "Könyvelési kódok"
            }
        },

        "contract-loading": {
            "innerText": {
                "hu": "A szerződés töltődik...",
                "en": "Loading contract..."
            }
        },
        "section-rentals": {
            "title": {
                "hu": "Bérleti díjak",
                "en": "Rental fees"
            },
        },
        "section-rentals-monthly": {
            "title": {
                "hu": "Havi bérleti díjak",
                "descr": null,
                "en": "Monthly Rental Fees"
            }
        },
        "section-rentals-daily": {
            "title": {
                "hu": "Napi bérleti díjak",
                "descr": null,
                "en": "Daily Rental Fees"
            }
        },
        "rental-code-label": {
            "innerText": {
                "en": "Code: Rental",
                "hu": "Kód: Bérleti díj",
                "descr": "rental-code-label"
            }
        },


        "code-dailyfee-label": {
            "innerText": {
                "en": "Code: Daily fee",
                "hu": "Kód: Napidíj",
                "descr": "code-dailyfee-label"
            }
        },
        "code-dailyfee-hint": {
            "innerText": {
                "en": "The accountancy code for daily fee",
                "hu": "A napidíj könyvelési kódja",
                "descr": "code-dailyfee-hint"
            }
        },
        "code-otta-label": {
            "innerText": {
                "en": "Code: OT & TA",
                "hu": "Kód: OT & TA",
                "descr": "code-otta-label"
            }
        },
        "code-otta-hint": {
            "innerText": {
                "en": "The accountancy code for OT and TA",
                "hu": "Az OT és TA könyvelési kódja",
                "descr": "code-otta-hint"
            }
        },
        "code-km-label": {
            "innerText": {
                "en": "Code: Fuel",
                "hu": "Kód: Üzemanyag",
                "descr": "code-km-label"
            }
        },
        "code-km-hint": {
            "innerText": {
                "en": "The accountancy code for fuel",
                "hu": "Az Üzemanyag könyvelési kódja",
                "descr": "code-km-hint"
            }
        },
        "code-parking-label": {
            "innerText": {
                "en": "Code: Parking",
                "hu": "Kód: Parkolás",
                "descr": "code-parking-label"
            }
        },
        "code-parking-hint": {
            "innerText": {
                "en": "The accountancy code for parking",
                "hu": "A parkolás könyvelési kódja",
                "descr": "code-parking-hint"
            }
        },
        "code-vignette-label": {
            "innerText": {
                "en": "Code: Vignette",
                "hu": "Kód: Matrica",
                "descr": "code-vignette-label"
            }
        },
        "code-vignette-hint": {
            "innerText": {
                "en": "The accountancy code for vignette",
                "hu": "A Matrica könyvelési kódja",
                "descr": "code-vignette-hint"
            }
        },
        "code-otherallowance-label": {
            "innerText": {
                "en": "Code: Other",
                "hu": "Kód: Egyéb",
                "descr": "code-otherallowance-label"
            }
        },
        "code-otherallowance-hint": {
            "innerText": {
                "en": "The accountancy code for other allowances",
                "hu": "Az egyéb szerződésen felüli teljesítések könyvelési kódja",
                "descr": "code-otherallowance-hint"
            }
        },
        "section-start-end": {
            "title": {
                "en": "Start and End",
                "hu": "Kezdete és vége",
            }
        },
        "section-start": {
            "title": {
                "en": "Start date",
                "hu": "Kezdete",
                "descr": "Kezdete"
            }
        },
        "startdate-label": {
            "innerText": {
                "en": "Start date",
                "hu": "Első munkanapod dátuma",
                "descr": "startdate-label"
            }
        },
        "section-other": {
            "title": {
                "en": "Contractual details",
                "hu": "Szerződés adatai",
                "descr": "Flex mezők section címe"
            }
        },
        "save-error": {
            "innerText": {
                "en": "Save failed",
                "hu": "A mentés nem sikerült",
                "descr": "A mentés nem sikerült"
            }
        },
        "send-error": {
            "innerText": {
                "en": "Send failed",
                "hu": "Nem sikerült beküldeni!",
                "descr": "Nem sikerült beküldeni!"
            }
        },
        "send-back-to-crew": {
            "innerText": {
                "en": "Send back to crew",
                "hu": "Visszaküldés a stábnak",
                "descr": "Visszaküldés a stábnak"
            },
            "title": {
                "en": "Send back to crew member",
                "hu": "Visszaküldés a stábtagnak",
                "descr": "Visszaküldés a stábtagnak"
            },
        },
        "send-back-error": {
            "innerText": {
                "en": "Send back failed",
                "hu": "Nem sikerült visszaküldeni!",
                "descr": "Nem sikerült visszaküldeni!"
            }
        },
        "btn-send-to-dept-head": {
            "innerText": {
                "en": "Send to department head",
                "hu": "Beküldés részlegvezetőnek",
                "descr": "Beküldés részlegvezetőnek"
            },
        },
        "btn-dept-approve": {
            "innerText": {
                "en": "Approve (Dept)",
                "hu": "Jóváhagyás (Részleg)",
                "descr": "Jóváhagyás"
            },
        },
        "btn-approve": {
            "title": {
                "en": "Approve",
                "hu": "Jóváhagyás",
                "descr": "Jóváhagyás"
            }
        },
        "approve-error": {
            "innerText": {
                "en": "Approve failed",
                "hu": "Nem sikerült jóváhagyni!",
                "descr": "Nem sikerült jóváhagyni!"
            }
        },
        "btn-ready-to-sign": {
            "title": {
                "en": "Ready to sign",
                "hu": "Aláírásra kész",
                "descr": "Aláírásra kész"
            }
        },
        "btn-signed": {
            "title": {
                "en": "Signed",
                "hu": "Aláírva",
                "descr": "Aláírva"
            }
        },
        "invalid-number-error": {
            "innerText": {
                "en": "Invalid number",
                "hu": "Érvénytelen szám",
                "descr": "Érvénytelen szám"
            }
        },
        "not-a-number-error": {
            "innerText": {
                "en": "Not a number",
                "hu": "Nem számot adtál meg",
                "descr": "Nem szám"
            }
        },
        "can-save-debug": {
            "title": {
                "en": "DEBUG mode enabled",
                "hu": "DEBUG mód bekapcsolva",
                "descr": "Title for the debug mode button"
            }
        },
        "can-save-saving": {
            "title": {
                "en": "Saving in progress...",
                "hu": "Mentés folyamatban...",
                "descr": "Title for the saving button"
            }
        },
        "can-save-no-change": {
            "title": {
                "en": "No changes on the form",
                "hu": "Nincs változtatás az űrlapon",
                "descr": "Title for the button when there are no changes on the form"
            }
        },
        "can-save-invalid": {
            "title": {
                "en": "Form is invalid",
                "hu": "Kitöltési hibák",
                "descr": "Title for the button when there are errors on the form"
            }
        },
        "can-save-save": {
            "title": {
                "en": "Save",
                "hu": "Mentés",
                "descr": "Title for the save button"
            }
        },
        "can-change-status-save-first": {
            "title": {
                "en": "Save changes first",
                "hu": "Előbb mentsd el a változtatásokat",
                "descr": "Title for the button when trying to change status without saving changes first"
            }
        },
        "can-change-status-unknown-contract": {
            "title": {
                "en": "Unknown start form",
                "hu": "Ismeretlen start form",
                "descr": "Title for the button when the start form is unknown"
            }
        },
        "can-change-status-unknown-status": {
            "title": {
                "en": "Unknown status",
                "hu": "Ismeretlen státusz",
                "descr": "Title for the button when the status is unknown"
            }
        },
        "btn-send-to-prodmgr-return": {
            "innerText": {
                "en": "Send back to production manager",
                "hu": "Visszaküldés gyártásvezetőnek",
                "descr": "Text for the button to send the contract back to the production manager"
            }
        },
        "btn-send-to-prodmgr": {
            "innerText": {
                "en": "Send to production manager",
                "hu": "Beküldés a gyártásvezetőnek",
                "descr": "Text for the button to send the contract to the production manager"
            }
        },
        "notif-msg": {
            "msg-empty-contract-cannot-save-as-new": {
                "hu": "Klónozás előtt a részleg és pozíció megadása kötelező",
                "en": "Cannot clone an empty start form"
            },
            "msg-save-as-new-confirm-disable-old-contract": {
                "hu": "Szeretnéd a régi start formot letiltani, hogy ne lehessen rá több timesheetet bevinni?",
                "en": "Do you want to disable the old start form so that no more timesheets can be submitted for it?"
            },
            "msg-save-as-new-confirm-title-disable-old-contract": {
                "hu": "Régi start form LETILTÁSA",
                "en": "DISABLE old start form"
            },
            "msg-save-as-new-confirm-no-button-text-disable-old-contract": {
                "hu": "NEM tiltom le",
                "en": "NO, do not disable"
            },
            "msg-save-as-new-confirm-cancel-button-text-disable-old-contract": {
                "hu": "Mégsem",
                "en": "Cancel"
            },
            "msg-ts-exists-error": {
                "hu": "Nem sikerült megállapítani, hogy a start formhoz létezik-e timesheet. A start form szerkesztése nem lehetséges. Kérlek, töltsd újra az oldalt!",
                "en": "Could not determine if there is a timesheet for this start form. Editing the start form is not possible. Please refresh the page!",
            },
            "msg-ts-exists": {
                "hu": "'A start formhoz már létezik timesheet. A pénznem nem szerkeszthető",
                "en": "There exists a timesheet for this start form. The currency cannot be changed",
            },
            "msg-ts-ot-exists": {
                "hu": "A start formhoz már létezik timesheet TÚLÓRÁVAL. Ha módosítod a túlóradíjat, módosul a jóváhagyásra váró timesheetek összege",
                "en": "There exists a timesheet with OVERTIME for this start form. If you change the OT rate, the amount will change for the timesheets not yet approved",
            },
            "msg-ts-ta-exists": {
                "hu": "A start formhoz már létezik timesheet PIHENŐIDŐVEL. Ha módosítod a pihenőidő díját, módosul a jóváhagyásra váró timesheet összege",
                "en": "There exists a timesheet with TURN AROUND for this start form. If you change the TA rate, the amount will change for the timesheets not yet approved",
            },
            "msg-file-upload-error": {
                "hu": "Hiba történt a fájl feltöltése közben",
                "en": "Error uploading file"
            },
            "msg-app-data-reload-failed": {
                "hu": "Az adatok mentése sikerült, de hiba történt az új adatok lekérése közben. Kérlek, frissítsd az oldalt!",
                "en": "Data saved successfully, but there was an error while reloading the data. Please refresh the page!",
            },
            "msg-invoice-contact-confirm": {
                "hu": "Ha megadsz számlázási kontaktot, nem láthatod a timesheetek pénzügyi adatait. Biztos ezt akarod?",
                "en": "If you provide an invoice contact, you will not be able to see the financial data of your timesheets. Are you sure?"
            },
            "msg-unknown-contract": {
                "en": "Unknown start form id: ",
                "hu": "Ismeretlen start form id: ",
                "descr": "Notification message when the start form is unknown"
            },
            "msg-load-error": {
                "en": "Error loading start form: ",
                "hu": "Hiba a startform betöltésekor: ",
                "descr": "Notification message when there is an error loading the start form"
            },
            "msg-missing-user": {
                "en": "Missing user who created the start form",
                "hu": "Nem létezik a start formot létrehozó felhasználó",
                "descr": "Notification message when the user who created the start form is missing"
            },
            "msg-missing-profile-fields": {
                "en": "Incomplete profile data! Fill out the following fields in the personal data in the PROFILE to submit the start form: ",
                "hu": "Hiányos profil adatok! A start form beküldéséhez előbb töltsd ki a PROFILban a következő személyes adatokat: ",
                "descr": "Notification message when there is incomplete profile data"
            },
            "msg-missing-contract-fields": {
                "en": "Fill out the following fields to submit the start form: ",
                "hu": "A start form beküldéséhez előbb töltsd ki a következő mezőket: ",
                "descr": "Notification message when there are missing fields on the form"
            },
            "msg-saving": {
                "en": "Saving data...",
                "hu": "Adatok mentése folyamatban...",
                "descr": "Notification message when data is being saved"
            },
            "msg-save-success": {
                "en": "Data saved successfully",
                "hu": "Az adatok mentése sikerült",
                "descr": "Notification message when data is saved successfully"
            },
            "msg-save-error": {
                "en": "Data save failed",
                "hu": "Az adatok mentése sikertelen",
                "descr": "Notification message when data save fails"
            },

        },
    },

    "app-day-selector": {
        "status-exists": {
            "innerText": {
                "en": "Timesheet exists for this day",
                "hu": "Már létezik timesheet erre a napra",
                "descr": "Status exists"
            }
        },
        "notif-msg": {
            "load-error": {
                "en": "Error loading timesheet",
                "hu": "Hiba a timesheet betöltésekor",
                "descr": "Error loading timesheet"
            },
        },
        "multi-select-days-hint": {
            "innerText": {
                "en": "Ctrl + click, or Shift + click to select multiple days",
                "hu": "Ctrl + kattintás, vagy Shift + kattintás több nap kiválasztásához",
                "descr": "multi-select-days-hint"
            }
        },
        "btn-refresh-text": {
            "innerText": {
                "en": "Reload",
                "hu": "Frissít",
                "descr": "btn-refresh-text"
            }
        }

    },

    "app-doclink": {
        "version": {
            "innerText": {
                "hu": "Verzió",
                "descr": "version",
                "en": "Version"
            }
        },
        "unknown-file": {
            "innerText": {
                "hu": "Ismeretlen file",
                "descr": "version",
                "en": "Unknown file"
            }
        },
        "removing": {
            "innerText": {
                "hu": ">A fájl törlése folyamatban...",
                "descr": "version",
                "en": "Removing file..."
            }
        },
        "btn-remove": {
            "innerText": {
                "hu": "Eltávolít",
                "descr": "btn-remove",
                "en": "Remove"
            }
        },
        "btn-pdf": {
            "title": {
                "en": "Send email with PDF attachment",
                "hu": "Elküldi emailen a PDF-et, mint mellékletet",
                "descr": ""
            },
            "innerText": {
                "en": "Kiküldés PDF-ként",
                "hu": "Kiküldés PDF-ként",
                "descr": "btn-pdf"
            }
        },
    },

    "mat-accordion": {
        "version": {
            "innerText": {
                "hu": "Verzió",
                "en": "Version"
            }
        },
        "btn-folder": {
            "innerText": {
                "hu": "Mappa",
                "en": "Folder"
            }
        },
        "gen-time": {
            "innerText": {
                "hu": "Készítés ideje",
                "descr": "gen-time",
                "en": "Time of generation"
            }
        },
        "gen-by": {
            "innerText": {
                "hu": "Készítette",
                "descr": "gen-by",
                "en": "Generated by"
            }
        },
        "btn-edit": {
            "title": {
                "hu": "Megnyitás szerkesztésre (ctrl + klikk = új fülön)",
                "descr": "",
                "en": "Open for editing (ctrl + click = new tab)"
            },
            "innerText": {
                "hu": "Szerkesztés",
                "descr": "btn-edit",
                "en": "Edit"
            }
        },
        "btn-preview": {
            "title": {
                "hu": "Megnyitás csak olvasásra (ctrl + klikk = új fülön)",
                "descr": "",
                "en": "Open for reading only (ctrl + click = new tab)"
            },
            "innerText": {
                "hu": "Előnézet",
                "descr": "btn-edit",
                "en": "Preview"
            }
        },
        "btn-pdf": {
            "title": {
                "hu": "Kiküldés PDF mellékletként",
                "descr": "",
                "en": "Send as PDF attachment"
            },
            "innerText": {
                "hu": "pdf",
                "descr": "btn-edit",
                "en": "pdf"
            }
        },
        "removing": {
            "innerText": {
                "hu": "A fájl törlése folyamatban...",
                "descr": "removing",
                "en": "Removing file..."
            }
        },
    },

    "app-crew-list": {
        "page-title": {
            "innerText": {
                "hu": "Stáblista",
                "descr": "page-title",
                "en": "Crew list"
            }
        },
        "name": {
            "innerText": {
                "en": "Name",
                "hu": "Név",
                "descr": "name"
            }
        },
        "phone": {
            "innerText": {
                "en": "Phone",
                "hu": "Telefon",
                "descr": "phone"
            }
        },
        "department": {
            "innerText": {
                "en": "Department",
                "hu": "Részleg",
                "descr": "department"
            }
        },
        "role": {
            "innerText": {
                "en": "Position",
                "hu": "Szerepkör",
                "descr": "role"
            }
        }
    },

    "app-flex-fields": {
        "option-select": {
            "innerText": {
                "en": 'Select',
                "hu": 'Válassz',
                "descr": 'Select',
            }
        },
    },

    "kendo-upload-messages": {
        "select": {
            "innerText": {
                "en": "Select files...",
                "hu": "Válassz fájlokat...",
                "descr": null
            }
        },
        "drop-files-here": {
            "innerText": {
                "en": "Drop files here",
                "hu": "Húzd ide a fájlokat",
                "descr": null
            }
        },
        "clear-selected-files": {
            "innerText": {
                "en": "Clear selected files",
                "hu": "Kiválasztott fájlok törlése",
                "descr": null
            }
        },
        "cancel": {
            "innerText": {
                "en": "Cancel",
                "hu": "Mégse",
                "descr": null
            }
        },
        "pause": {
            "innerText": {
                "en": "Pause",
                "hu": "Szüneteltetés",
                "descr": null
            }
        },
        "resume": {
            "innerText": {
                "en": "Resume",
                "hu": "Folytatás",
                "descr": null
            }
        },
        "retry": {
            "innerText": {
                "en": "Retry",
                "hu": "Újra",
                "descr": null
            }
        },
        "upload-selected-files": {
            "innerText": {
                "en": "Upload selected files",
                "hu": "Feltöltés",
                "descr": null
            }
        },
        "remove": {
            "innerText": {
                "en": "Remove",
                "hu": "Eltávolít",
                "descr": null
            }
        },
        "file-status-failed": {
            "innerText": {
                "en": "Failed",
                "hu": "Sikertelen",
                "descr": null
            }
        },
        "file-status-uploaded": {
            "innerText": {
                "en": "Uploaded",
                "hu": "Feltöltve",
                "descr": null
            }
        },
        "header-status-paused": {
            "innerText": {
                "en": "Paused",
                "hu": "Szüneteltetve",
                "descr": null
            }
        },
        "header-status-uploaded": {
            "innerText": {
                "en": "Uploaded",
                "hu": "Feltöltve",
                "descr": null
            }
        },
        "header-status-uploading": {
            "innerText": {
                "en": "Uploading",
                "hu": "Feltöltés folyamatban",
                "descr": null
            }
        }

    },

    "app-header": {
        "my-profile": {
            "innerText": {
                "hu": "Személyes adatok",
                "descr": "my-profile",
                "en": "My Profile",
            }
        },
        "my-contracts": {
            "innerText": {
                "hu": "Start Formjaim",
                "descr": "my-contracts",
                "en": "My Start Forms",
            }
        },
        "my-ts": {
            "innerText": {
                "hu": "Timesheetjeim",
                "descr": "my-ts",
                "en": "My Timesheets",
            }
        },
        "logout": {
            "innerText": {
                "hu": "Kijelentkezés",
                "descr": "logout",
                "en": "Logout",
            }
        },
    },


    "app-edit-timesheet": {
        "to-dept-head": {
            "innerText": {
                "hu": " Részlegvezetőnek",
                "en": " to Head of Department"
            }
        },
        "btn-disabled-contract-not-approved": {
            "title": {
                "hu": "Ez a start form még nincs engedélyezve",
                "en": "This start form is not yet approved"
            },
            "descr": {
                "hu": "Addig nem vihetsz be timesheetet, amíg a start form nincs engedélyezve",
                "en": "You may not submit any timesheets until the corresponding start form is not fully approved"
            }
        },
        "btn-disabled-ts-not-editable": {
            "title": {
                "hu": "Letiltott timesheet nem szerkeszthető",
                "en": "A disabled timesheet cannot be edited"
            },
            "descr": {
                "hu": "Kérd a gyártásvezetőt, hogy hagyja jóvá vagy engedélyezze a szerkesztést",
                "en": "Ask the production manager to approve or enable editing"
            }
        },
        "check-age": {
            "too-old-title": {
                "hu": "Túl régi timesheet",
                "en": "Timesheet too old",
            },
            "too-old": {
                "hu": "Két hétnél régebbi timesheet. A timesheet létrejött, de le lett tiltva. Fordulj a gyártásvezetőhöz egyedi elbíráslásért!",
                "en": "Timesheet older than 2 weeks. The timesheet is created but not submitted. Contact the production manager for individual consideration!",
            },
            "too-old-no-ot": {
                "hu": "OT igényt csak a forgatás másnapjáig fogadunk el. Módosítsd a kezdő vagy a végeidőt, hogy ne legyen túlóra igényed, vagy keresd a gyártásvezetőt egyedi elbírálásért!",
                "en": "OT may be claimed only for the day after the shooting day. Modify your start or end time so that you have no OT, or contact the production manager for individual consideration!",
            },
            "submit-as-disabled": {
                "hu": "Mentés így, egyeztetek a gyártásvezetővel",
                "en": "Save like this, I will contact the production manager",
            },
            "modify-times": {
                "hu": "Időpontok módosítása",
                "en": "Modify times",
            }
        },
        "selected-contract": {
            "innerText": {
                "en": "Selected Position",
                "hu": "Kiválasztott szerepkör",
                "descr": ""
            }
        },

        "page-warning": {
            "title": {
                "en": "The Production Manager's notice",
                "hu": "A gyártásvezető üzenete",
                "descr": "A gyártásvezető üzenete"
            }
        },
        "date-label": {
            "innerText": {
                "en": "Date",
                "hu": "Dátum",
                "descr": "date-label"
            }
        },
        "date-hint": {
            "innerText": {
                "en": "Which day is this timesheet for?",
                "hu": "Melyik napra viszed be az adatokat?",
                "descr": "date-hint"
            }
        },
        "workhours-label": {
            "innerText": {
                "en": "Number of workhours",
                "hu": "Óraszám",
                "descr": "workhours-label"
            }
        },
        "starttime-label": {
            "innerText": {
                "en": "Start time",
                "hu": "Hány órakor kezdtél?",
                "descr": "starttime-label"
            }
        },
        "endtime-label": {
            "innerText": {
                "en": "End time",
                "hu": "Hány órakor végeztél?",
                "descr": "endtime-label"
            }
        },
        "page-title": {
            "title": {
                "en": "Edit Timesheet",
                "hu": "Timesheet szerkesztése",
                "descr": "Timesheet szerkesztése"
            }
        },
        "btn-delete-already-deleted": {
            "title": {
                "en": "Already deleted",
                "hu": "Már törölve",
                "descr": "Már törölve"
            }
        },
        "can-submit-no--contract": {
            "title": {
                "en": "Select a position!",
                "hu": "Válassz szerepkört!",
                "descr": "Válassz szerepkört!"
            }
        },
        "can-submit-form-invalid": {
            "title": {
                "en": "The form data is not valid",
                "hu": "Az űrlap nincs helyesen kitöltve",
                "descr": "Az űrlap nincs helyesen kitöltve"
            }
        },
        "can-submit-form-not-saved": {
            "title": {
                "en": "Save changes first",
                "hu": "Előbb mentsd el a változtatásokat",
                "descr": "Előbb mentsd el a változtatásokat"
            }
        },
        "can-submit-saving": {
            "title": {
                "en": "Saving in progress",
                "hu": "Mentés folyamatban",
                "descr": "Mentés folyamatban"
            }
        },
        "can-submit-overlap": {
            "title": {
                "en": "Overlap with another timesheet",
                "hu": "Átfedés egy másik timesheettel",
                "descr": "Átfedés egy másik timesheettel"
            }
        },
        "can-submit-inv-accepted": {
            "title": {
                "en": "Invoice already accepted",
                "hu": "Számla már befogadva",
                "descr": "Számla már befogadva"
            }
        },
        "can-submit-att-generating": {
            "title": {
                "en": "Attachment is being generated",
                "hu": "Számlamelléklet épp generálás alatt van",
                "descr": "Számlamelléklet épp generálás alatt van"
            }
        },
        "can-submit-already-submitted": {
            "title": {
                "en": "Already submitted",
                "hu": "Már beküldve",
                "descr": "Már beküldve"
            }
        },
        "can-submit-future": {
            "title": {
                "en": "Future timesheet cannot be submitted",
                "hu": "Jövőbeli timesheet nem küldhető be",
            }
        },
        "can-submit-no-permission": {
            "title": {
                "en": "No permission",
                "hu": "Nincs jogosultságod",
                "descr": "Nincs jogosultságod"
            }
        },
        "can-submit-not-waiting-approval": {
            "title": {
                "en:": "Not waiting for approval",
                "hu": "Nem vár jóváhagyásra",
            }
        },
        "can-save-debug": {
            "title": {
                "en": "DEBUG enabled",
                "hu": "DEBUG bekapcsolva",
                "descr": "DEBUG bekapcsolva"
            }
        },
        "can-save-saving": {
            "title": {
                "en": "Saving in progress...",
                "hu": "Mentés folyamatban...",
                "descr": "Mentés folyamatban..."
            }
        },
        "can-save-no-change": {
            "title": {
                "en": "No changes on the form",
                "hu": "Nincs változtatás az űrlapon",
                "descr": "Nincs változtatás az űrlapon"
            }
        },
        "can-save-form-invalid": {
            "title": {
                "en": "Form date is invalid",
                "hu": "Kitöltési hibák",
                "descr": "Kitöltési hibák"
            }
        },
        "can-save": {
            "title": {
                "en": "Save",
                "hu": "Mentés",
                "descr": "Mentés"
            }
        },
        "notif-msg": {
            "cannot-save-too-old-to-modify-date": {
                "hu": "Túl régi, a dátum nem módosítható. Mentés nem lehetséges.",
                "en": "Too old. You may not change the date"
            },
            "holiday-warning": {
                "hu": "ünnepnap. Biztos, hogy dolgoztál?",
                "en": "is a holiday. Are you sure you worked?"
            },
            "holiday-warning-title": {
                "hu": "Ünnepnap",
                "en": "Holiday"
            },
            "i-am-sure-button-text": {
                "hu": "Biztos",
                "en": "I am sure"
            },
            "unknown-ts": {
                "en": "Unknown Timesheet",
                "hu": "Ismeretlen Timesheet",
                "descr": "Ismeretlen Timesheet"
            },
            "multiple-ts-from-route": {
                "en": "Error loading Timesheet",
                "hu": "Timesheet betöltés hiba",
                "descr": "Timesheet betöltés hiba"
            },
            "ts-load-error": {
                "en": "Error loading Timesheet",
                "hu": "Hiba történt a Timesheet betöltése közben",
                "descr": "Hiba történt a Timesheet betöltése közben"
            },
            "submitting": {
                "en": "Submitting...",
                "hu": "Küldés...",
                "descr": "Küldés..."
            },
            "db-error": {
                "en": "Database error. Maybe the save failed",
                "hu": "Adatbázis hiba. Talán nem sikerült a mentés",
                "descr": "Adatbázis hiba. Talán nem sikerült a mentés"
            },
            "cannot-save-error": {
                "en": "Cannot save: ",
                "hu": "Nem menthető: ",
                "descr": "Nem menthető: "
            },
            "save-success": {
                "en": "Save succeeded",
                "hu": "A mentés sikerült",
                "descr": "A mentés sikerült"
            },
            "save-error": {
                "en": "Save failed",
                "hu": "Sikertelen mentés",
                "descr": "Sikertelen mentés"
            },
            "nth-ts-cannot-save-error": {
                "en": "th timesheet cannot be saved: ",
                "hu": ". timesheet nem menthető: ",
                "descr": ". timesheet nem menthető: "
            },
            "submit-success": {
                "en": "Timesheet submitted.",
                "hu": "Timesheet beküldve.",
                "descr": "Timesheet beküldve."
            },
            "save-error-colon": {
                "en": "Save failed: ",
                "hu": "Sikertelen mentés: ",
                "descr": "Sikertelen mentés: "
            },
            "status-change-success": {
                "en": "Status change succeeded",
                "hu": "Státusz módosítás sikerült",
                "descr": "Státusz módosítás sikerült"
            },
            "status-change-error": {
                "en": "Status change failed",
                "hu": "Sikertelen státusz módosítás",
                "descr": "Sikertelen státusz módosítás"
            },
            "cannot-save-no-contract": {
                "en": "No position selected.",
                "hu": "Nincs kiválasztva szerepkör.",
                "descr": "Nincs kiválasztva szerepkör."
            },
            "cannot-save-no-from-time": {
                "en": "The start time is not specified.",
                "hu": "Nincs megadva kezdési idő.",
                "descr": "Nincs megadva kezdési idő."
            },
            "cannot-save-no-to-time": {
                "en": "The end time is not specified.",
                "hu": "Nincs megadva befejezési idő.",
                "descr": "Nincs megadva befejezési idő."
            },
            "cannot-save-no-workhours": {
                "en": "The number of workhours is not specified.",
                "hu": "Nincs megadva munkaóra.",
                "descr": "Nincs megadva munkaóra."
            },
            "cannot-save-negative-workhours": {
                "en": "The number of workhours cannot be negative.",
                "hu": "A munkaóra nem lehet negatív.",
                "descr": "A munkaóra nem lehet negatív."
            },
            "cannot-save-too-many-workhours": {
                "en": "The number of workhours cannot be more than 24 hours.",
                "hu": "A munkaóra nem lehet több, mint 24 óra.",
                "descr": "A munkaóra nem lehet több, mint 24 óra."
            },
            "cannot-save-invalid-from-time": {
                "en": "The start time is not in a valid format.",
                "hu": "A kezdési idő nem megfelelő formátumú.",
                "descr": "A kezdési idő nem megfelelő formátumú."
            },
            "cannot-save-invalid-to-time": {
                "en": "The end time is not in a valid format.",
                "hu": "A befejezési idő nem megfelelő formátumú.",
                "descr": "A befejezési idő nem megfelelő formátumú."
            }
        },
        "send-back": {
            "are-you-sure": {
                "en": "Are you sure you want to send this timesheet back? Why?",
                "hu": "Valóban visszaküldöd? Miért?",
                "descr": "Valóban visszaküldöd? Miért?"
            },
            "save-button-text": {
                "en": "Send back",
                "hu": "Visszaküldés",
                "descr": "Visszaküldés"
            }
        },
        "delete": {
            "are-you-sure": {
                "en": "Are you sure you want to delete this timesheet?",
                "hu": "Biztos törlöd?",
                "descr": "Biztos törlöd?"
            },
            "save-button-text": {
                "en": "Delete",
                "hu": "Törlés",
                "descr": "Törlés"
            }
        },
        "btn-save": {
            "innerText": {
                "en": "Save",
                "hu": "Mentés",
                "descr": "btn-save"
            }
        },
        "btn-approve": {
            "innerText": {
                "en": "Approve",
                "hu": "Jóváhagy",
                "descr": "btn-approve"
            }
        },
        "btn-approve-dept": {
            "innerText": {
                "en": "Approve (Dept)",
                "hu": "Jóváhagy (Részleg)",
                "descr": "btn-approve"
            }
        },
        "btn-reject": {
            "innerText": {
                "en": "Send back",
                "hu": "Visszaküld",
                "descr": "btn-reject"
            }
        },
        "btn-delete": {
            "innerText": {
                "en": "Delete",
                "hu": "Törlés",
                "descr": "btn-delete"
            }
        },
        "required-error": {
            "innerText": {
                "en": "This field is required",
                "hu": " A mező kitöltése kötelező ",
                "descr": "required-error"
            }
        },
        "btn-submit": {
            "innerText": {
                "en": "Submit",
                "hu": "Beküldés",
                "descr": "btn-submit"
            }
        },
        "history": {
            "innerText": {
                "en": "History",
                "hu": "Előzmények",
                "descr": "history"
            }
        },
        "history-load-error": {
            "innerText": {
                "en": "Error loading history",
                "hu": "Hiba az előzmények betöltésekor",
                "descr": "history-load-error"
            }
        },


    },


    "app-adjacent-timesheets": {
        "notif-msg": {
            "load-error": {
                "en": "Error loading adjacent timesheets",
                "hu": "Nem lehetett ellenőrizni az időpont átfedéseket: ",
                "descr": "Nem lehetett ellenőrizni az időpont átfedéseket: "
            }
        },
    },

    "app-my-timesheets": {
        "page-title": {
            "innerText": {
                "en": "My Timesheets",
                "hu": "Timesheetjeim",
                "descr": "page-title"
            }
        },
        "selected-timesheets-title": {
            "innerText": {
                "en": "Selected Timesheet",
                "hu": "Kiválasztott Timesheet",
                "descr": "selected-timesheets-title"
            }
        },
        "new-ts-button": {
            "innerText": {
                "en": "New TS",
                "hu": "Új TS",
                "descr": "new-ts-button"
            }
        },
        "page-title-my-ts": {
            "title": {
                "en": "My Timesheets",
                "hu": "Timesheetjeim",
                "descr": "Timesheetjeim"
            }
        }
    },

    "app-new-timesheet": {
        "page-title": {
            "innerText": {
                "en": "New Timesheet",
                "hu": "Új Timesheet",
                "descr": "Új Timesheet"
            }
        },
        "selected-contract-title": {
            "innerText": {
                "en": "Selected Start Form/Position",
                "hu": "Kiválasztott Tevékenység/Start Form",
                "descr": "selected-contract-title"
            }
        }
    },

    "app-dept-defaults": {
        "dept-detaults-h1": {
            "innerText": {
                "en": "Department Defaults",
                "hu": "Részlegek alapértelmezései",
                "descr": "Department Defaults"
            }
        },
        "seach-textbox-text": {
            "innerText": {
                "en": "Search",
                "hu": "Keresés",
                "descr": "Search"
            }
        },
        "add-dept": {
            "innerText": {
                "en": "Add Department",
                "hu": "Új részleg",
                "descr": "Add Department"
            }
        },
    },

    "app-search": {
        "label": {
            "innerText": {
                "en": "Search",
                "hu": "Keresés",
            }
        },
    },

    "app-filter-contract-type": {
        "label": {
            "innerText": {
                "en": "Contract type",
                "hu": "Szerződéstípus",
            }
        },
    },

    "app-filter-con-status": {
        "label": {
            "innerText": {
                "en": "Contract status",
                "hu": "Szerződés státusz",
            }
        },
    },

    "app-filter-date-range": {
        "start-label": {
            "innerText": {
                'en': 'Start date',
                'hu': 'Első nap',
            }
        },
        "end-label": {
            "innerText": {
                'en': 'End date',
                'hu': 'Utolsó nap',
            }
        },
    },

    "app-salary-reasons": {
        "minute": {
            "innerText": {
                "hu": "perc",
                "descr": "minute",
                "en": "minute"
            }
        },
        "start-label": {
            "innerText": {
                "en": "Start:",
                "hu": "Kezdés:",
                "descr": "start-label"
            }
        },
        "as-in-contract-label": {
            "innerText": {
                "en": "As in contract",
                "hu": "Szerződés szerint",
                "descr": "as-in-contract-label"
            }
        },
        "as-calculated-label": {
            "innerText": {
                "en": "Calculated value",
                "hu": "Kalkulált érték",
                "descr": "as-calculated-label"
            }
        },
        "as-saved-label": {
            "innerText": {
                "en": "Approved value",
                "hu": "Jóváhagyott érték",
                "descr": "as-saved-label"
            }
        },
        "saved-daily-fee": {
            "innerText": {
                "en": "approved daily fee",
                "hu": "jóváhagyott napidíj",
                "descr": "saved-daily-fee"
            }
        },
        "daily-fee-label": {
            "innerText": {
                "en": "Daily fee:",
                "hu": "Napidíj:",
                "descr": "daily-fee-label"
            }
        },
        "calculated-daily-fee": {
            "innerText": {
                "en": "Calculated daily fee",
                "hu": "kalkulált napidíj",
                "descr": "calculated-daily-fee"
            }
        },
        "normal-daily-rate": {
            "innerText": {
                "en": "Normal daily rate",
                "hu": "normál napidíj",
                "descr": "normal-daily-rate"
            }
        },
        "during-preprod": {
            "innerText": {
                "en": "during preproduction",
                "hu": "előkészítés alatt",
                "descr": "during-preprod"
            }
        },

        "perdiem-value": {
            "innerText": {
                "en": "Per Diem fee",
                "hu": "Per Diem díj jár",
                "descr": "perdiem-value"
            }
        },
        "halfday": {
            "innerText": {
                "en": "Half day work",
                "hu": "Fél nap munka volt",
                "descr": "halfday"
            }
        },

        "travelday-half-fee": {
            "innerText": {
                "en": "Half fee for travel day",
                "hu": "Utazónapra fél napidíj jár",
                "descr": "travelday-half-fee"
            }
        },
        "calculated-ot-fee-value": {
            "innerText": {
                "en": "OT fee for this day",
                "hu": "túlóradíj jár erre a napra",
                "descr": "calculated-ot-fee-value"
            }
        },
        "ot-rate-value": {
            "innerText": {
                "en": "contracted OT rate",
                "hu": "a szerződéses túlóradíj",
                "descr": "ot-rate-value"
            }
        },
        "ot-step-value": {
            "innerText": {
                "en": "hour steps",
                "hu": "órás lépésekben",
                "descr": "ot-step-value"
            }
        },
        "saved-ot-value": {
            "innerText": {
                "en": "approved OT fee",
                "hu": "a jóváhagyott túlóradíj",
                "descr": "saved-ot-value"
            }
        },
        "workhours-value": {
            "innerText": {
                "en": "required workhours",
                "hu": "óra előírt munkaidő",
                "descr": "workhours-value"
            }
        },
        "ot-hours-value": {
            "innerText": {
                "en": "OT hours",
                "hu": "óra túlóra",
                "descr": "ot-hours-value"
            }
        },
        "calculated-ta-fee-value": {
            "innerText": {
                "en": "calculated TA fee",
                "hu": "kalkulált TA díj",
                "descr": "calculated-ta-fee-value"
            }
        },
        "ta-hours-value": {
            "innerText": {
                "en": "TA hours",
                "hu": "órával kevesebb pihenőidő volt",
                "descr": "ta-hours-value"
            }
        },
        "ta-rate-value": {
            "innerText": {
                "en": "TA rate",
                "hu": "TA díj óránként",
                "descr": "ta-rate-value"
            }
        },
        "saved-ta-fee": {
            "innerText": {
                "en": "saved TA fee",
                "hu": "elmentett TA díj",
                "descr": "saved-ta-fee"
            }
        },
        "ta-step-value": {
            "innerText": {
                "en": "hour steps",
                "hu": "órás lépésekben",
                "descr": "ta-step-value"
            }
        },
        "short-rest-hours": {
            "innerText": {
                "en": "short rest hours",
                "hu": "órás rövid pihenőidő jár",
                "descr": "short-rest-hours"
            }
        },
        "prev-day-end-value": {
            "innerText": {
                "en": "Previous workday end:",
                "hu": "Előző munkanap vége:",
                "descr": "prev-day-end-value"
            }
        },

    },

    "app-save-comment": {
        "question-text": {
            "innerText": {
                "en": "Please, explain the change",
                "hu": "Kérlek, indokold meg a változtatást",
                "descr": "Kérlek, indokold meg a változtatást"
            }
        },
        "save-button-text": {
            "innerText": {
                "en": "Save",
                "hu": "Mentés",
                "descr": "Mentés"
            }
        },
        "cancel-button-text": {
            "innerText": {
                "en": "Cancel",
                "hu": "Mégse",
                "descr": "Mégse"
            }
        }
    },

    "app-select-contract": {
        "not-all-clickable": {
            "title": {
                "hu": "Jóváhagyás nélküli start form",
                "en": "Start Form without approval"
            },
            "description": {
                "hu": "Csak a Pénzügy által jóváhagyott start formokra lehet timesheetet bevinni. Ha nem küldted még be a start formodat, kérlek küldd be mihamarabb. Ha már beküldted, kérjük várj türelemmel, amíg a gyártásvezető és a pénzügy jóváhagyja ",
                "en": "You may only submit a timesheet for a start form approved by Finance."
            }
        },
        "no-contract-warning": {
            "title": {
                "en": "You have no Start Form",
                "hu": "Még nincs Start Formod",
                "descr": "Még nincs start formod"
            },
            "description": {
                "en": "You can create one now!",
                "hu": "Hozz létre egyet most!",
                "descr": "Hozz létre egyet most!"
            }
        },

        "new-role": {
            "innerText": {
                "en": "New Position",
                "hu": "Új szerepkör",
                "descr": "new-role"
            }
        },
    },

    "app-ts-editor-readonly": {
        "monthly-rentals": {
            "innerText": {
                "hu": "Havi bérlet",
                "en": "Monthly Rent"
            }
        },
        "km-fee": {
            "innerText": {
                "hu": "km díj",
                "en": "km fee"
            }
        },
        "parking": {
            "innerText": {
                "hu": "Parkolás",
                "en": "Parking"
            }
        },
        "vignette": {
            "innerText": {
                "hu": "Matrica",
                "en": "Vignette"
            }
        },
        "daily-rentals": {
            "innerText": {
                "hu": "Napi bérlet",
                "descr": "daily-rentals",
                "en": "Daily Rentals"
            }
        },
        "db": {
            "innerText": {
                "hu": "db",
                "descr": "db",
                "en": "pcs"
            }
        },
        "daily-rate-label": {
            "innerText": {
                "en": "Daily fee",
                "hu": "Napidíj",
                "descr": "daily-rate-label"
            }
        },
        "hours": {
            "innerText": {
                "en": "hours",
                "hu": "óra",
                "descr": "hours"
            }
        },
        "other-allowance-label": {
            "innerText": {
                "en": "Other allowance",
                "hu": "Szerződésen felüli",
                "descr": "other-allowance-label"
            }
        },
        "calc-details": {
            "innerText": {
                "en": "How was it calculated?",
                "hu": "Hogy jött ki?",
                "descr": "calc-details"
            }
        },
        "perdiem-label": {
            "innerText": {
                "en": "Per Diem",
                "hu": "Per Diem",
                "descr": "perdiem-label"
            }
        },
    },

    "app-ot-ta-bulk-changer": {
        "page-title": {
            "innerText": {
                "hu": "OT /TA tömeges beállítása",
                "en": "OT / TA bulk changer"
            }
        },
        "required-field-error": {
            "innerText": {
                "hu": " Kötelező kitölteni ",
                "en": " Required"
            }
        },
    },
    
    "app-locations": {
        "save": {
            "innerText": {
                "hu": "Mentés",
                        "en": "Save"
            }
        }
    },
    "app-holidays": {
        "save": {
            "innerText": {
                "hu": "Mentés",
                "en": "Save"
            }
        }
    },

    "app-history": {
        "page-title": {
            "innerText": {
                "en": "History",
                "hu": "Előzmények",
                "descr": "Előzmények"
            }
        },
        "history-loading": {
            "innerText": {
                "en": "Loading...",
                "hu": "Töltődik...",
                "descr": "history-loading"
            }
        },
        "not-saved-yet": {
            "innerText": {
                "en": "Not saved yet",
                "hu": "Nem történt még mentés",
                "descr": "not-saved-yet"
            }
        },
        "by": {
            "innerText": {
                "en": "by",
                "hu": "módosította:",
                "descr": "by"
            }
        },
        "btn-reload": {
            "innerText": {
                "en": "Reload",
                "hu": "Újra",
                "descr": "btn-reload"
            }
        },
        "notif-msg": {
            "error-loading-history": {
                "en": "Error loading history",
                "hu": "Hiba az előzmények betöltésekor",
                "descr": "Hiba az előzmények betöltésekor"
            },
        },
    },

    "notif-msg": {
        "confirm-title": {
            "innerText": {
                "hu": "Biztos vagy benne?",
                "descr": null,
                "en": "Are you sure?"
            }
        },
        "confirm-cancel": {
            "innerText": {
                "hu": "Mégse",
                "descr": null,
                "en": "Cancel"
            }
        }
    },

    "app-page-loading": {
        "loading": {
            "innerText": {
                "en": "Loading...",
                "hu": "Betöltés...",
                "descr": "loading"
            }
        },
    },

    "guard": {
        "error": {
            "innerText": {
                "en": "You don't have permission to access this page",
                "hu": "Magasabb szintű jogosultság szükséges",
                "descr": null
            }
        }
    },


    "my-wts": {
        "error-generating-wts": {
            "innerText": {
                "hu": "Hiba történt a számlamelléklet generálása közben",
                "descr": null,
                "en": null
            }
        }
    },


    "pipe": {
        "no-data": {
            "innerText": {
                "hu": "Nincs adat",
                "en": "No data"
            }
        },
        "view-wts": {
            "innerText": {
                "hu": "WTS megtekintése",
                "en": "View WTS"
            }
        },
        "day": {
            "innerText": {
                "en": "day",
                "hu": "nap",
            }
        },
        "ts-grid.field.code_salary": {
            "innerText": {
                "hu": "Napidíj kód",
                "en": "Salary code"
            }
        },
        "ts-grid.field.code_otta": {
            "innerText": {
                "hu": "OT/TA kód",
                "en": "OT/TA code"
            }
        },
        "ts-grid.field.code_gas": {
            "innerText": {
                "hu": "Üzemanyag kód",
                "en": "Gas code"
            }
        },
        "ts-grid.field.code_park": {
            "innerText": {
                "hu": "Parkolás kód",
                "en": "Parking code"
            }
        },
        "ts-grid.field.code_vignette": {
            "innerText": {
                "hu": "Matrica kód",
                "en": "Vignette code"
            }
        },
        "ts-grid.field.code_perdiem": {
            "innerText": {
                "hu": "Per Diem kód",
                "en": "Per Diem code"
            }
        },
        "ts-grid.field.code_other": {
            "innerText": {
                "hu": "Egyéb kód",
                "en": "Other code"
            }
        },
        "ts-grid.field.minstartdate": {
            "innerText": {
                "hu": "Első nap",
                "en": "First day"
            }
        },
        "ts-grid.field.maxstartdate": {
            "innerText": {
                "hu": "Utolsó nap",
                "en": "Last day"
            }
        },
        "ts-grid.field.total": {
            "innerText": {
                "hu": "Összes díj",
                "en": "Total"
            }
        },
        "otherfee": {
            "innerText": {
                "hu": "Egyéb díj",
                "en": "Other fee"
            }
        },
        "Vignette": {
            "innerText": {
                "hu": "Matrica",
                "en": "Vignette"
            }
        },
        "Gas": {
            "innerText": {
                "hu": "Üzemanyag",
                "en": "Gas"
            }
        },
        "Parking": {
            "innerText": {
                "hu": "Parkolás",
                "en": "Parking"
            }
        },
        "Dailyrentalrate": {
            "innerText": {
                "hu": "Napi bérleti dí",
                "en": "Daily rental rat"
            }
        },
        "Monthlyrentalrate": {
            "innerText": {
                "hu": "Havi bérleti díj",
                "en": "Monthly rental rate"
            }
        },
        "ts-grid.field.othours": {
            "innerText": {
                "hu": "Túlóra",
                "en": "OT hours"
            }
        },
        "ts-grid.field.otfee": {
            "innerText": {
                "hu": "Túlóradíj",
                "en": "OT fee"
            }
        },
        "ts-grid.field.tahours": {
            "innerText": {
                "hu": "TA óra",
                "en": "TA hours"
            }
        },
        "ts-grid.field.tafee": {
            "innerText": {
                "hu": "TA díj",
                "en": "TA fee"
            }
        },
        "ts-grid.field.mealpenaltyhours": {
            "innerText": {
                "hu": "Meal Penalty óra",
                "en": "Meal Penalty"
            }
        },
        "ts-grid.field.mealpenalty": {
            "innerText": {
                "hu": "Meal Penalty díj",
                "en": "Meal Penalty fee"
            }
        },
        "ts-grid.field.otherfee": {
            "innerText": {
                "hu": "Egyéb díj",
                "en": "Other fee"
            }
        },
        "ts-grid.field.perdiem": {
            "innerText": {
                "hu": "Per Diem",
                "en": "Per Diem"
            }
        },
        "ts-grid.field.vignette": {
            "innerText": {
                "hu": "Matrica",
                "en": "Vignette"
            }
        },
        "ts-grid.field.gas": {
            "innerText": {
                "hu": "Üzemanyag",
                "en": "Gas"
            }
        },
        "ts-grid.field.parking": {
            "innerText": {
                "hu": "Parkolás",
                "en": "Parking"
            }
        },
        "ts-grid.field.tscount": {
            "innerText": {
                "hu": "TS db",
                "en": "TS count"
            }
        },
        "ts-grid.field.dailyrentalrate1name": {
            "innerText": {
                "hu": "Napi bérleti díj 1 neve",
                "en": "Daily rental rate 1 name"
            }
        },
        "ts-grid.field.dailyrentalrate1": {
            "innerText": {
                "hu": "Napi bérleti díj 1",
                "en": "Daily rental rate 1"
            }
        },
        "ts-grid.field.dailyrentalrate2name": {
            "innerText": {
                "hu": "Napi bérleti díj 2 neve",
                "en": "Daily rental rate 2 name"
            }
        },
        "ts-grid.field.dailyrentalrate2": {
            "innerText": {
                "hu": "Napi bérleti díj 2",
                "en": "Daily rental rate 2"
            }
        },
        "ts-grid.field.dailyrentalrate3name": {
            "innerText": {
                "hu": "Napi bérleti díj 3 neve",
                "en": "Daily rental rate 3 name"
            }
        },
        "ts-grid.field.dailyrentalrate3": {
            "innerText": {
                "hu": "Napi bérleti díj 3",
                "en": "Daily rental rate 3"
            }
        },
        "ts-grid.field.dailyrentalrate4name": {
            "innerText": {
                "hu": "Napi bérleti díj 4 neve",
                "en": "Daily rental rate 4 name"
            }
        },
        "ts-grid.field.dailyrentalrate4": {
            "innerText": {
                "hu": "Napi bérleti díj 4",
                "en": "Daily rental rate 4"
            }
        },
        "ts-grid.field.dailyrentalrate5name": {
            "innerText": {
                "hu": "Napi bérleti díj 5 neve",
                "en": "Daily rental rate 5 name"
            }
        },
        "ts-grid.field.dailyrentalrate5": {
            "innerText": {
                "hu": "Napi bérleti díj 5",
                "en": "Daily rental rate 5"
            }
        },
        "ts-grid.field.dailyrentalrate6name": {
            "innerText": {
                "hu": "Napi bérleti díj 6 neve",
                "en": "Daily rental rate 6 name"
            }
        },
        "ts-grid.field.dailyrentalrate6": {
            "innerText": {
                "hu": "Napi bérleti díj 6",
                "en": "Daily rental rate 6"
            }
        },
        "ts-grid.field.monthlyrentalrate1name": {
            "innerText": {
                "hu": "Havi bérleti díj 1 neve",
                "en": "Monthly rental rate 1 name"
            }
        },
        "ts-grid.field.monthlyrentalrate1": {
            "innerText": {
                "hu": "Havi bérleti díj 1",
                "en": "Monthly rental rate 1"
            }
        },
        "ts-grid.field.monthlyrentalrate2name": {
            "innerText": {
                "hu": "Havi bérleti díj 2 neve",
                "en": "Monthly rental rate 2 name"
            }
        },
        "ts-grid.field.monthlyrentalrate2": {
            "innerText": {
                "hu": "Havi bérleti díj 2",
                "en": "Monthly rental rate 2"
            }
        },
        "ts-grid.field.monthlyrentalrate3name": {
            "innerText": {
                "hu": "Havi bérleti díj 3 neve",
                "en": "Monthly rental rate 3 name"
            }
        },
        "ts-grid.field.monthlyrentalrate3": {
            "innerText": {
                "hu": "Havi bérleti díj 3",
                "en": "Monthly rental rate 3"
            }
        },
        "hours": {
            "innerText": {
                "hu": "óra",
                "en": "hours"
            }
        },
        "export-to-excel": {
            "innerText": {
                "hu": "Exportálás Excelbe",
                "en": "Export to Excel"
            }
        },
        "username": {
            "innerText": {
                "hu": "Név",
                "en": "Name"
            }
        },
        "department": {
            "innerText": {
                "hu": "Részleg",
                "en": "Department"
            }
        },
        "position": {
            "innerText": {
                "hu": "Poizíció",
                "en": "Position"
            }
        },
        "salary": {
            "innerText": {
                "hu": "Napidíj",
                "en": "Daily fee"
            }
        },
        "combine-rentals": {
            "innerText": {
                "hu": "Összevonás számlaképben",
                "en": "Combine in Invoice"
            }
        },
        "save": {
            "innerText": {
                "hu": "Mentés",
                "en": "Save"
            }
        },
        "currency-mismatch-error": {
            "innerText": {
                "hu": "Különböző pénznemek!",
                "en": "Currencies don't match"
            }
        },
        "weeks": {
            "innerText": {
                "hu": "hét",
                "en": "weeks"
            }
        },
        "salary-mismatch": {
            "innerText": {
                "hu": "Jóváhagyott és kalkulált napidíj különbözik!",
                "en": "Approved and calculated day's fee differ"
            }
        },
        "user-grid.field.deptAndPosConcat": {
            "innerText": {
                "hu": "Részleg & Pozíció",
                "en": "Department and Position"
            }
        },
        "save-successful": {
            "innerText": {
                "hu": "Mentés OK",
                "en": "Saved."
            }
        },
        "deleted-user": {
            "innerText": {
                "hu": "Törölt felhasználó",
                "en": "Deleted user"
            }
        },
        "exchange-rate-loading": {
            "innerText": {
                "hu": "Az árfolyam töltödik...",
                "en": "Loading exchange rates..."
            }
        },
        "exchange-rate-error": {
            "innerText": {
                "hu": "Árfolyam hiba",
                "en": "Exchange rate error"
            }
        },
        "daily-rental-fees-title": {
            "innerText": {
                "hu": "Napi bérleti díjak",
                "en": "Daily Rental Fees"
            }
        },
        "none": {
            "innerText": {
                "hu": "Nincs",
                "en": "None"
            }
        },
        "btn-finance-approve": {
            "innerText": {
                "hu": "Jóváhagyás (Pénzügy)",
                "en": "Finance Approve"
            }
        },
        "btn-approve-as-prodmgr": {
            "innerText": {
                "hu": " (Gyártásvezető)",
                "en": " (Prod mgr)"
            },
        },
        "more": {
            "innerText": {
                "hu": "Több...",
                "en": "More..."
            }
        },
        "search": {
            "innerText": {
                "hu": "Keresés",
                "en": "Search"
            }
        },
        "disabled-contracts": {
            "innerText": {
                "hu": "Letiltottak is",
                "en": "Disabled"
            }
        },
        "notifSaving": {
            "innerText": {
                "hu": "Mentés folyamatban...",
                "en": "Saving..."
            }
        },
        "notifSaveError": {
            "innerText": {
                "hu": "Mentés hiba",
                "en": "Save error"
            }
        },
        "add-new": {
            "innerText": {
                "hu": "Új",
                "en": "Add new"
            }
        },
        "remove": {
            "innerText": {
                "hu": "Törlés",
                "en": "Remove"
            }
        },
        "add": {
            "innerText": {
                "hu": "Hozzáadás",
                "en": "Add"
            }
        },
        "discard-changes": {
            "innerText": {
                "hu": "Mégse",
                "en": "Discard changes"
            }
        },
        "update": {
            "innerText": {
                "hu": "Mentés",
                "en": "Update"
            }
        },
        "cancel": {
            "innerText": {
                "hu": "Mégse",
                "en": "Cancel"
            }
        },
        "approved": {
            "innerText": {
                "hu": "Jóváhagyva",
                "en": "Approved"
            }
        },
        "not-approved": {
            "innerText": {
                "hu": "Nincs jóváhagyva",
                "en": "Not approved"
            }
        },
        "partially-approved": {
            "innerText": {
                "hu": "Részben jóváhagyva",
                "en": "Partially approved"
            }
        },
        "weekly-ts-sent": {
            "innerText": {
                "hu": "WTS kiküldve",
                "en": "WTS sent"
            }
        },
        "weekly-ts-not-sent": {
            "innerText": {
                "hu": "WTS nincs kiküldve",
                "en": "WTS not sent"
            }
        },
        "some-weekly-ts-sent": {
            "innerText": {
                "hu": "Néhány napra WTS kiküldve",
                "en": "WTS sent for some days"
            }
        },
        "invoice-accepted": {
            "innerText": {
                "hu": "Számla befogadva",
                "en": "Invoice accepted"
            }
        },
        "invoice-not-accepted": {
            "innerText": {
                "hu": "Számla nincs befogadva",
                "en": "Invoice not yet accepted"
            }
        },
        "some-invoice-accepted": {
            "innerText": {
                "hu": "Néhány napra számla befogadva",
                "en": "Invoice accepted for some days"
            }
        },
        "set-invoice-accepted": {
            "innerText": {
                "hu": "Szla befogadva",
                "en": "Invoice accepted"
            }
        },
        "last-wts-day": {
            "innerText": {
                "hu": "Utolsó WTS: ",
                "en": "Last WTS: "
            }
        },
        "inv-attachment": {
            "innerText": {
                "en": "Invoice attachment",
                "hu": "Számlakép",
            }
        },
        "zero-salary-warning": {
            "innerText": {
                "hu": "Nincs megadva napidíj!",
                "en": "No daily rate!"
            }
        },
        "user-grid.field.usid": {
            "innerText": {
                "hu": "Adatbázis azonosító",
                "en": null
            }
        },
        "contract-grid.field.conid": {
            "innerText": {
                "hu": "Adatbázis azonosító",
                "en": null
            }
        },
        "contract-grid.field.usid": {
            "innerText": {
                "hu": "Stábtag azonosító",
                "en": null
            }
        },
        "reload": {
            "innerText": {
                "en": "Reload",
                "hu": "Frissítés",
            }
        },
        "load": {
            "innerText": {
                "en": "Load",
                "hu": "Betöltés",
            }
        },
        "all-ts-approved": {
            "innerText": {
                "en": "All timesheets are approved",
                "hu": "Minden timesheet jóváhagyva",
            }
        },
        'loading-timesheets': {
            'innerText': {
                'en': 'Loading timesheets...',
                'hu': 'Timesheetek betöltése...',
                'descr': 'from pipe: loading-timesheets'
            }
        },
        "send-email-title": {
            "innerText": {
                "en": "Send email",
                "hu": "Email küldés",
            }
        },
        "calculated-title": {
            "innerText": {
                "en": "Calculated from the entered data",
                "hu": "A bevitt adatok alapján számított napidíj",
            }
        },
        "overriden-daily-rate-title": {
            "innerText": {
                "en": "Overriden by the production manager",
                "hu": "Amit a gyártásvezető megadott",
            }
        },
        "km-fee-title": {
            "innerText": {
                "en": "km fee",
                "hu": "km díj",
            }
        },
        "pariking-fee-title": {
            "innerText": {
                "en": "Parking fee",
                "hu": "Parkolás díj",
            }
        },
        "vign-fee-title": {
            "innerText": {
                "en": "Vignette fee",
                "hu": "Matrica",
            }
        },
        "other-fee-title": {
            "innerText": {
                "en": "Other Allowance",
                "hu": "Egyéb díj",
            }
        },
        "pd-etc-title": {
            "innerText": {
                "en": "Per Diem / Travel day / Half day /Monthly rent due / Meal Penalty",
                "hu": "Per Diem / Utazó nap / Fél nap / Havi bérleti díj esedékes / Meal Penalty",
            }
        },
        "ts-grid.field.usname": {
            "innerText": {
                "en": "Crew member",
                "hu": "Stábtag",
            }
        },
        "ts-grid.field.depname": {
            "innerText": {
                "en": "Department",
                "hu": "Részleg",
            }
        },
        "ts-grid.field.role": {
            "innerText": {
                "en": "Position",
                "hu": "Munkakör",
            }
        },
        "ts-grid.field.dDate": {
            "innerText": {
                "en": "Date",
                "hu": "Dátum",
            }
        },
        "ts-grid.field.dStartDate": {
            "innerText": {
                "en": "Time",
                "hu": "Idő",
            }
        },
        "ts-grid.field.comment": {
            "innerText": {
                "en": "Comment",
                "hu": "Megjegyzés",
            }
        },
        "set-approved": {
            "innerText": {
                "en": "Set: \"Approved\"",
                "hu": "Új státuszt: Jóváhagyva",
            }
        },
        "set-at-prodmgr": {
            "innerText": {
                "en": "Set: \"At Prod Mgr\"",
                "hu": "Új státuszt: Gyártásvezetőre vár",
            }
        },
        "edit": {
            "innerText": {
                "en": "Edit",
                "hu": "Szerkesztés",
            }
        },
        "return-to-crew": {
            "innerText": {
                "en": "Return to crew",
                "hu": "Visszaküldés stábtagnak",
            }
        },
        "return-to-prodmgr": {
            "innerText": {
                "en": "Return to production manager",
                "hu": "Visszaküldés gyártásvezetőnek",
            }
        },
        "retract-approval": {
            "innerText": {
                "en": "Retract approval",
                "hu": "Jóváhagyás visszavonása",
            }
        },
        "return-to-approved": {
            "innerText": {
                "en": "Return to approved status",
                "hu": "Vissza jóváhagyva státuszba",
            }
        },
        "send-to-prodmgr": {
            "innerText": {
                "en": "Submit to production manager",
                "hu": "Beküldés gyártásvezetőnek",
            }
        },
        "approve": {
            "innerText": {
                "en": "Approve",
                "hu": "Jóváhagyás",
            }
        },
        "set-sent": {
            "innerText": {
                "en": "Set: \"Sent\"",
                "hu": "Új státusz: Szla mell. kiküldve",
            }
        },
        "daily-rate": {
            "innerText": {
                "en": "Daily fee",
                "hu": "Napidíj",
            }
        },
        "calculated": {
            "innerText": {
                "en": "Calculated:",
                "hu": "Kalkulált:",
            }
        },
        "saved": {
            "innerText": {
                "en": "Approved",
                "hu": "Jóváhagyott",
            }
        },

        "contract-grid.field.crewColumn": {
            "innerText": {
                "en": "Crew member",
                "hu": "Stábtag",
            }
        },
        "contract-grid.field.depname": {
            "innerText": {
                "en": "Department",
                "hu": "Részleg",
            }
        },
        "contract-grid.field.role": {
            "innerText": {
                "en": "Position",
                "hu": "Munkakör",
            }
        },
        "contract-grid.field.contractTypeAsPropItem.piname": {
            "innerText": {
                "en": "Contract type",
                "hu": "Szerződés típus",
            }
        },
        "btn-contract-set-readyToSign": {
            "innerText": {
                "en": "Set: Ready to sign",
                "hu": "Új státuszt: Aláírásra kész",
            }
        },
        "btn-contract-approve": {
            "innerText": {
                "en": "Approve",
                "hu": "Jóváhagy",
            }
        },
        "contract-grid.field.f_companyname": {
            "innerText": {
                "en": "Company name",
                "hu": "Cégnév",
            }
        },

        "btn-contract-edit": {
            "innerText": {
                "en": "Edit",
                "hu": "Szerkeszt",
            }
        },
        "btn-contract-return-to-crew": {
            "innerText": {
                "en": "Return to crew",
                "hu": "Stabátagnak visszaküld",
            }
        },
        "btn-contract-return-to-prodmgr": {
            "innerText": {
                "en": "Return to production manager",
                "hu": "Gyártásvezetőhöz küld",
            }
        },
        "btn-contract-set-approved": {
            "innerText": {
                "en": "Set: Prod Mgr Approved",
                "hu": "Új státusz: Gyv jóváhagyta",
            }
        },
        "btn-contract-set-finance-approved": {
            "innerText": {
                "en": "Set: Finance Approved",
                "hu": "Új státusz: Pénzügy jóváhagyta",
            }
        },
        "btn-contract-set-signed": {
            "innerText": {
                "en": "Set: Signed",
                "hu": "Új státusz: Aláírva",
            }
        },
        "btn-contract-delete": {
            "innerText": {
                "en": "Delete",
                "hu": "Törlés",
            }
        },
        "btn-contract-disable": {
            "innerText": {
                "en": "Disable",
                "hu": "Inaktiválás",
            }
        },


        "user-grid.field.famname": {
            "innerText": {
                "hu": "Vezetéknév",
                "en": "Family name"
            }
        },
        "user-grid.field.usname": {
            "innerText": {
                "hu": "Keresztnév",
                "en": "First name (given name)"
            }
        },
        "user-grid.field.phonenumber": {
            "innerText": {
                "hu": "Telefonszám",
                "en": "Phone number"
            }
        },
        "user-grid.field.ugm": {
            "innerText": {
                "hu": "App szerepek",
                "en": "App roles"
            }
        },
        "all": {
            "innerText": {
                "hu": "Összes",
                "en": "All"
            }
        },
        "delete": {
            "innerText": {
                "hu": "Törlés",
                "en": "Delete"
            }
        },
        "enable": {
            "innerText": {
                "hu": "Engedélyezés",
                "en": "Enable"
            }
        },
        "disable": {
            "innerText": {
                "hu": "Tiltás",
                "en": "Disable"
            }
        },
            "release-from-disable": {
                "innerText": {
                    "hu": "Tiltás feloldása",
                    "descr": "from pipe: release-from-disable",
                    "en": "Enable"
            }
        }

    },

    "td": {
        "daily-rental-fees": {
            "innerText": {
                "hu": "Napi bérleti díjak",
                "en": "Daily Rental Fees"
            }
        },
        "deleted": {
            "innerText": {
                "en": "DELETED",
                "hu": "TÖRÖLVE",
                "descr": "deleted"
            }
        },
        "contract-end-date": {
            "innerText": {
                "en": "Contract end date",
                "hu": "Várható végzés időpontja",
            }
        }
    },

    "div": {
        "select-ts": {
            "innerText": {
                "hu": "Válassz Timesheetet",
                "en": "Select Timesheet"
            }
        },
        "hour-suffix": {
            "innerText": {
                "hu": " óra",
                "en": " hour"
            }
        },
        "only-if-not": {
            "innerText": {
                "hu": "Csak ha nem",
                "en": "Only if not"
            }
        },
        "perdiem-hint": {
            "innerText": {
                "hu": "Az extra összeg, amely ottalvós napokon jár. ",
                "en": "The extra amount that is due on overnight days."
            }
        },
        "s_001": {
            "innerText": {
                "hu": "Regisztráció elfogadva:",
                "descr": "s_001",
                "en": "Registration accepted:"
            }
        },
        "save": {
            "innerText": {
                "hu": "Mentés",
                "en": "Save"
            }
        },
        "ts-is-holiday": {
            "innerText": {
                "hu": "Ez egy ünnepnap",
                "descr": "ts-is-holiday",
                "en": "This is a holiday"
            }
        },
        "btn-folder": {
            "innerText": {
                "hu": "Mappa",
                "descr": "btn-folder",
                "en": "Folder"
            }
        },
        "tel-pattern-error": {
            "innerText": {
                "hu": " A helyes formátum: +36 30 123 1234 ",
                "descr": "tel-pattern-error",
                "en": " The correct format: +36 30 123 1234 "
            }
        },
        "dept-members": {
            "innerText": {
                "en": "deprtment members",
                "hu": "fő a részlegen",
            }
        },
        "new-ts-button": {
            "innerText": {
                "hu": "New TS",
                "descr": "new-ts-button",
                "en": "Új TS"
            }
        },
        "history-error": {
            "innerText": {
                "en": "Error loading history",
                "hu": "Hiba az előzmények betöltésekor",
                "descr": "Hiba az előzmények betöltésekor"
            }
        },

        "me_001": {
            "innerText": {
                "en": "Your name is required",
                "hu": " A neved megadása kötelező",
                "descr": "me_001"
            }
        },
        "me_002": {
            "innerText": {
                "en": "Your phone number is required",
                "hu": " A telefonszám megadása kötelező",
                "descr": "me_002"
            }
        },
        "btn-preview": {
            "innerText": {
                "hu": "Kiküldött PDF",
                "en": "Sent PDF",
            },
        },
        "new-version-avaliable": {
            "innerText": {
                "hu": "Új verzió elérhető. Kérlek frissíts! ",
                "en": "New version available. Please refresh! "
            },
            "title": {
                "hu": "Az app újratöltődik",
                "en": "The app will reload"
            },
        },
        "update": {
            "innerText": {
                "hu": "Frissítés",
                "en": "Update"
            },
            "title": {
                "hu": "Az app újratöltődik",
                "en": "The app will reload"
            },
        },
        "is-actual": {
            "innerText": {
                skipWarnMissing: true,
             },
            "title": {
                "hu": "Aktuális érték",
                "descr": "",
                "en": "Actual value"
            },
        },
        "go-to-contract": {
            "innerText": {
                "hu": "Tovább a szerződéshez",
                "descr": "go-to-contract",
                "en": "Go to Start Forms"
            }
        },
        "nothing-uploaded": {
            "innerText": {
                "en": "Not yet uploaded a file",
                "hu": "Még nem töltöttél fel fájlt",
                "descr": "nothing-uploaded"
            }
        },

        "no-overlap": {
            "innerText": {
                "en": "No overlap",
                "hu": "Nincs időpont átfedés",
                "descr": "no-overlap"
            }
        },
        "ts-already-exists": {
            "innerText": {
                "en": "This Timesheet is already submitted",
                "hu": "Ezt a Timesheetet már bevitted",
                "descr": "ts-already-exists"
            }
        },
        "your-last-ts": {
            "innerText": {
                "en": "Your last Timesheet:",
                "hu": "Utolsó Timesheeted:",
                "descr": "your-last-ts"
            }
        },
        "your-next-ts": {
            "innerText": {
                "en": "Your next Timesheet:",
                "hu": "Következő Timesheeted:",
                "descr": "your-next-ts"
            }
        },
        "no-saved-value": {
            "innerText": {
                "en": "no saved value",
                "hu": "nincs mentett érték",
                "descr": "no-saved-value"
            }
        },
        "no-perdiem": {
            "innerText": {
                "en": "No Per Diem",
                "hu": "Nem jár Per Diem",
                "descr": "no-perdiem"
            }
        },
        "no-halfday": {
            "innerText": {
                "en": "Not a half-day",
                "hu": "Nem volt fél nap",
                "descr": "no-halfday"
            }
        },
        "not-a-travelday": {
            "innerText": {
                "en": "Not a travel day",
                "hu": "Nem volt utazónap",
                "descr": "not-a-travelday"
            }
        },
        "no-prev-day": {
            "innerText": {
                "en": "This is the first workday",
                "hu": "Ez az első munkanap",
                "descr": "no-prev-day"
            }
        },
        "overlapping-ts-title": {
            "innerText": {
                "en": "Overlapping Timesheet",
                "hu": "Időpont átfedés",
                "descr": "overlapping-ts-title"
            }
        },
        "ts-overlaps-body": {
            "innerText": {
                "en": "Another Timesheet overlaps with this one",
                "hu": "Egy másik Timesheet összeér ezzel",
                "descr": "ts-overlaps-body"
            }
        },
        "view": {
            "innerText": {
                "en": "View",
                "hu": "Megnéz",
                "descr": "view"
            }
        },
        "deleted-or-empty": {
            "innerText": {
                "en": "Deleted or empty",
                "hu": "Törölve vagy üres érték",
                "descr": "deleted-or-empty"
            }
        },
    },

    "section": {
        "ta-short-len-label": {
            "innerText": {
                "hu": "Rövid pihenőidő hossza órában",
                "en": "Short TA length is hours"
            }
        },
        "ta-long-len-label": {
            "innerText": {
                "hu": "Hosszú pihenőidő hossza órában",
                "en": "Long TA length in hours"
            }
        },
        "ta-rate-label": {
            "innerText": {
                "hu": "TA óradíj",
                "descr": "ta-rate-label",
                "en": "TA hourly rate"
            }
        },
        "saved-fees-title": {
            "innerText": {
                "hu": "Jóváhagyott",
                "en": "Approved"
            }
        },
        "to-prod-mgr": {
            "innerText": {
                "hu": " Gyártásvezetőnek",
                "en": " to Prod Mgr"
            }
        },
        "inherited": {
            "innerText": {
                "hu": "Örökölt",
                "en": "Inherited"
            }
        },
        "dept-default": {
            "innerText": {
                "hu": "Részleg alapértelmezései",
                "en": "Department default"
            }
        },
        "prod-default": {
            "innerText": {
                "hu": "Produkció alapértelmezései",
                "en": "Production default"
            }
        },
        "only-if-not": {
            "innerText": {
                "en": "Only if not",
                "hu": "Csak ha nem",
            }
        },
        "hour-suffix": {
            "innerText": {
                "en": " hour",
                "hu": " óra",
            }
        },
    },

    "form": {
        "code-otta-label": {
            "innerText": {
                "en": "Code: OT & TA",
                "hu": "Kód: OT & TA",
                "descr": "code-otta-label"
            }
        },
        "code-otta-hint": {
            "innerText": {
                "en": "The accountancy code for OT and TA",
                "hu": "Az OT és TA könyvelési kódja",
                "descr": "code-otta-hint"
            }
        },
        "code-km-label": {
            "innerText": {
                "en": "Code: Fuel",
                "hu": "Kód: Üzemanyag",
                "descr": "code-km-label"
            }
        },
        "code-km-hint": {
            "innerText": {
                "en": "The accountancy code for fuel",
                "hu": "Az Üzemanyag könyvelési kódja",
                "descr": "code-km-hint"
            }
        },
        "code-parking-label": {
            "innerText": {
                "en": "Code: Parking",
                "hu": "Kód: Parkolás",
                "descr": "code-parking-label"
            }
        },
        "code-parking-hint": {
            "innerText": {
                "en": "The accountancy code for parking",
                "hu": "A parkolás könyvelési kódja",
                "descr": "code-parking-hint"
            }
        },
        "code-vignette-label": {
            "innerText": {
                "en": "Code: Vignette",
                "hu": "Kód: Matrica",
                "descr": "code-vignette-label"
            }
        },
        "code-vignette-hint": {
            "innerText": {
                "en": "The accountancy code for vignette",
                "hu": "A Matrica könyvelési kódja",
                "descr": "code-vignette-hint"
            }
        },
        "code-otta-find-in-prod-defaults": {
            "innerText": {
                "hu": "Az OT/TA kódot a produkció alapértelmezéseinél találod",
                "en": "Find the OT/TA codes at the Production Defaults page"
            }
        },
        "code-km-find-in-prod-defaults": {
            "innerText": {
                "hu": "Az üzemanyag hozzájárulás kódot a produkció alapértelmezéseinél találod",
                "en": "Find the Gas codes at the Production Defaults page"
            }
        },
        "code-parking-find-in-prod-defaults": {
            "innerText": {
                "hu": "A parkolás kódot a produkció alapértelmezéseinél találod",
                "en": "Find the Parking codes at the Production Defaults page"
            }
        },
        "code-vignette-find-in-prod-defaults": {
            "innerText": {
                "hu": "A matrica kódot a produkció alapértelmezéseinél találod",
                "en": "Find the Vignette codes at the Production Defaults page"
            }
        },
        "saved-fees-title": {
            "innerText": {
                "hu": "Jóváhagyott",
                "en": "Approved"
            }
        },
        "minute-suffix": {
            "innerText": {
                "hu": " perc",
                "en": " minutes"
            }
        },
        "meal-penalty-label": {
            "innerText": {
                "hu": "Meal Penalty egy teljes órára",
                "en": "Meal Penalty for a full hour"
            }
        },
        "inherited": {
            "innerText": {
                "hu": "Örökölt",
                "en": "Inherited"
            }
        },
        "value-in-start-form": {
            "innerText": {
                "hu": "Start formban megadott érték",
                "en": "Value in start form"
            }
        },
        "enddate-label": {
            "innerText": {
                "hu": "Várható befejezés dátuma",
                "en": "Expected end date",
            }
        },
        "enddate-hint": {
            "innerText": {
                "hu": "Ez után a dátum után nem küldhetsz be timesheetet",
                "en": "You cannot submit timesheets after this date",
            }
        },
        "ot-step-label": {
            "innerText": {
                "en": "OT step",
                "hu": "Túlóra lépcső",
                "descr": "or-step-label"
            }
        },
        "ot-rate-label": {
            "innerText": {
                "en": "OT rate per hour",
                "hu": "OT óradíj",
                "descr": "or-rate-label"
            }
        },
        "monthly-rental-name-label": {
            "innerText": {
                "en": "Monthly rental fee name",
                "hu": "Havi bérleti díj tárgya",
            }
        },
        "monthly-rental-rate-label": {
            "innerText": {
                "en": "Monthly rental rate",
                "hu": "Havi bérleti díj összege",
            }
        },
        "contracted-fee-label": {
            "innerText": {
                "en": "Contracted fee",
                "hu": "Szerződéses díj",
                "descr": "contracted-fee-label"
            }
        },
        "contracted-fee-hint": {
            "innerText": {
                "en": "Only if you have a one-time fee in the contract",
                "hu": "Csak egyösszegű szerződés esetén",
                "descr": "contracted-fee-hint"
            }
        },
        "history": {
            "innerText": {
                "en": "History",
                "hu": "Előzm.",
                "descr": "history"
            },
            "title": {
                "en": "History",
                "hu": "Előzmények",
            }
        },
        "currency": {
            "innerText": {
                "en": "Currency",
                "hu": "Elszámolás pénzneme",
                "descr": "currency"
            }
        },
        "if-not": {
            "innerText": {
                "en": "If not",
                "hu": "Ha nem",
                "descr": "if-not"
            }
        },
        "preprod-fee-label": {
            "innerText": {
                "en": "Daily fee during pre-production",
                "hu": "Napidíj előkészítés alatt",
                "descr": "preprod-fee-label"
            }
        },
        "preprod-fee-hint": {
            "innerText": {
                "en": "Only if your daily fee is DIFFERENT during pre-production",
                "hu": "Csak akkor kell kitölteni, ha a forgatás előtt MÁS napidíj jár",
                "descr": "preprod-fee-hint"
            }
        },
        "daily-fee-label": {
            "innerText": {
                "en": "Daily fee",
                "hu": "Napidíj",
                "descr": "daily-fee-label"
            }
        },
        "daily-fee-hint": {
            "innerText": {
                "en": "Normal daily fee",
                "hu": "Normál napidíj",
                "descr": "daily-fee-hint"
            }
        },
        "daily-rental-name-label": {
            "innerText": {
                "en": "Daily rental fee name",
                "hu": "Napi bérleti díj tárgya",
            },
        },
        "daily-rental-rate-label": {
            "innerText": {
                "en": "Daily rental rate",
                "hu": "Napi bérleti díj összege",
            }
        },
        "location-label": {
            "innerText": {
                "hu": "Forgatási helyszín",
                "en": "Location"
            }

        },
        "monthly-rent-due-label": {
            "innerText": {
                "en": "Monthly rent due",
                "hu": "Havi bérleti díj esedékes",
            }
        },
        "remark-to-prodmgr": {
            "innerText": {
                "en": "Remark to the Production Manager",
                "hu": "Üzenet a gyártásvezetőnek",
                "descr": "remark-to-prodmgr"
            }
        },
        "km-fee-label": {
            "innerText": {
                "en": "Km fee",
                "hu": "Km díj",
                "descr": "km-fee-label"
            }
        },
        "km-fee-hint": {
            "innerText": {
                "en": "Requested amount",
                "hu": "Igényelt összeg",
                "descr": "km-fee-hint"
            }
        },
        "km-reason-label": {
            "innerText": {
                "en": "Km reason",
                "hu": "Km indoklás",
                "descr": "km-reason-label"
            }
        },
        "km-reason-hint": {
            "innerText": {
                "en": "Distance in km, and why?",
                "hu": "Hány km-t kívánsz elszámolni?",
                "descr": "km-reason-hint"
            }
        },
        "parking-fee-label": {
            "innerText": {
                "en": "Parking fee",
                "hu": "Parkolás díj",
                "descr": "parking-fee-label"
            }
        },
        "parking-fee-hint": {
            "innerText": {
                "en": "Requested amount",
                "hu": "Igényelt összeg",
                "descr": "parking-fee-hint"
            }
        },
        "parking-reasone-label": {
            "innerText": {
                "en": "Parking reason",
                "hu": "Parkolás indoklás",
                "descr": "parking-reasone-label"
            }
        },
        "parking-reason-hint": {
            "innerText": {
                "en": "Where did you park, and why?",
                "hu": "Hol parkoltál, és miért?",
                "descr": "parking-reason-hint"
            }
        },
        "vignette-fee-label": {
            "innerText": {
                "en": "Vignette fee",
                "hu": "Matrica díj",
                "descr": "vignette-fee-label"
            }
        },
        "vignette-fee-hint": {
            "innerText": {
                "en": "Requested amount",
                "hu": "Igényelt összeg",
                "descr": "vignette-fee-hint"
            }
        },
        "vignette-reasone-label": {
            "innerText": {
                "en": "Vignette type",
                "hu": "Matrica típusa",
                "descr": "vignette-reasone-label"
            }
        },
        "half-day-label": {
            "innerText": {
                "en": "Half day",
                "hu": "Fél nap",
                "descr": "half-day-label"
            }
        },
        "travel-day-label": {
            "innerText": {
                "en": "Travel day",
                "hu": "Utazó nap",
                "descr": "travel-day-label"
            }
        },
        "perdiem-label": {
            "innerText": {
                "en": "Per Diem",
                "hu": "Per Diem",
                "descr": "perdiem-label"
            }
        },
        "other-allowance-label": {
            "innerText": {
                "en": "Other allowance",
                "hu": "Szerződésen felüli",
                "descr": "other-allowance-label"
            }
        },
        "other-allowance-hint": {
            "innerText": {
                "en": "Other allowance",
                "hu": "Egyéb szerződésen felüli teljesítés",
                "descr": "other-allowance-label"
            }
        },
        "salary-override-label": {
            "innerText": {
                "en": "Salary Override",
                "hu": "Napidíj felülírása",
                "descr": "salary-override-label"
            }
        },
        "salary-override-hint": {
            "innerText": {
                "en": "If the calculated salary doesn't apply",
                "hu": "Ha a kalkulált napidíj nem érvényes",
                "descr": "salary-override-hint"
            }
        },
        "calc-fees-title": {
            "innerText": {
                "en": "Calculated fees",
                "hu": "Kalkulált díjak",
                "descr": "calc-fees-title"
            }
        },
        "not-a-number-error": {
            "innerText": {
                "en": "Not a number",
                "hu": "Nem számot adtál meg",
                "descr": "Nem szám"
            }
        },

        "dept-default": {
            "innerText": {
                "hu": "Részleg alapértelmezései",
                "descr": "dept-default",
                "en": "Department default"
            }
        },
        "prod-default": {
            "innerText": {
                "hu": "Produkció alapértelmezései",
                "descr": "prod-default",
                "en": "Production default"
            }
        },
        "perdiem-hint": {
            "innerText": {
                "hu": "Az extra összeg, amely ottalvós napokon jár. ",
                "en": "The extra amount that is due on overnight days."
            }
        },
        "only-if-not": {
            "innerText": {
                "hu": "Csak ha nem",
                "en": "Only if not"
            }
        },
        "ta-short-len-label": {
            "innerText": {
                "hu": "Rövid pihenőidő hossza",
                "descr": "ta-short-len-label",
                "en": "Short rest length"
            }
        },
        "hour-suffix": {
            "innerText": {
                "hu": " óra",
                "en": " hour"
            }
        },
        "ta-long-len-label": {
            "innerText": {
                "hu": "Hosszú pihenőidő hossza",
                "descr": "ta-long-len-label",
                "en": "Long rest length"
            }
        },
        "ta-rate-label": {
            "innerText": {
                "hu": "TA óradíj",
                "descr": "ta-rate-label",
                "en": "TA hourly rate"
            }
        },
        "rental-code-label": {
            "innerText": {
                "hu": "Kód: Bérleti díj",
                "descr": "rental-code-label",
                "en": "Code: Rental fee"
            }
        }

    },

    "prod.service": {
        "error-loading-prod-defaults": {
            "innerText": {
                "hu": "Hiba a gyártás alapadatainak betöltése közben",
                "en": "Error loading production defaults",
            },
        },
        "error-saving-prod-defaults": {
            "innerText": {
                "hu": "Hiba a gyártás alapadatainak mentése közben",
                "en": "Error saving production defaults",
            },
        },
    },

    "contract.service": {
        "handleContracts": {
            "saveError": {
                "hu": "A mentés nem adott vissza start formot, ezért lehet, hogy nem mentődött el",
                "en": "No start form found in the result of the saving so it is possibly not saved correctly"
            }
        },
        "getStatusName": {
            "undefined": {
                "hu": "Nincs beküldve",
                "en": "Not submitted"
            },
            "ContractStatus.disabled": {
                "hu": "Letiltva",
                "en": "Disabled"
            },
            "ContractStatus.deleted": {
                "hu": "Törölve",
                "en": "Deleted"
            },
            "ContractStatus.crewMember": {
                "hu": "Beküldésre vár",
                "en": "Waiting for sending"
            },
            "ContractStatus.deptHead": {
                "hu": "Részlegvezetőre vár",
                "en": "Waiting for Dept. Head"
            },
            "ContractStatus.deptHeadApproved": {
                "hu": "Részlegvezető jóváhagyta",
                "en": "Dept. Head approved"
            },
            "ContractStatus.prodMgr": {
                "hu": "Gyártásvezetőre vár",
                "en": "Waiting for Prod. Mgr."
            },
            "ContractStatus.prodMgrApproved": {
                "hu": "Gyv Jóváhagyta",
                "en": "Prod Mgr Approved"
            },
            "ContractStatus.financeApproved": {
                "hu": "Pénzügy jóváhagyta",
                "en": "Finance Approved"
            },
            "ContractStatus.readyToSign": {
                "hu": "Aláírásra kész",
                "en": "Ready to sign"
            },
            "ContractStatus.signed": {
                "hu": "Aláírva",
                "en": "Signed"
            },
            "default": {
                "hu": "Ismeretlen státusz",
                "en": "Unknown state"
            }
        },
        "canSetAtDeptHead": {
            "ContractStatus.deptHead.noDeptHead": {
                "hu": "Nincs még részlegvezető",
                "en": "No department head",
            },
            "ContractStatus.deptHead.send": {
                "hu": "Beküldöd a részlegvezetőnek",
                "en": "Send it to the department head",
            },
            "ContractStatus.crewMember.isOwnContract": {
                "hu": "Beküldöd a részlegvezetőnek",
                "en": "Send it to the department head",
            },
            "ContractStatus.crewMember.crewStillEditing": {
                "hu": "Még a stábtag szerkeszti",
                "en": "Still edited by crew member",
            },
            "ContractStatus.atDeptHead": {
                "hu": "A start form már részlegvezetői jóváhagyásra vár",
                "en": "Already sent to department head",
            },
            "ContractStatus.deptHeadApproved": {
                "hu": "A start formot a részlegvezető már jóváhagyta",
                "en": "Already approved by department head",
            },
        },
        "canSetAtProdMgr": {
            "debug": {
                "hu": "Debug üzemmódban vagy",
                "en": "You are in debug mode"
            },
            "nocontract": {
                "hu": "Nincs létrehozva a start form",
                "en": "Start form not created yet"
            },
            "ContractStatus.deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "saving": {
                "hu": "Mentés folyamatban...",
                "en": "Saving in progress..."
            },
            "ContractStatus.crewMember.isOwnContract": {
                "hu": "Beküldöd a produkciónak",
                "en": "Submit to production"
            },
            "ContractStatus.crewMember.notOwnContract": {
                "hu": "Még a stábtag szerkeszti",
                "en": "Still edited by crew member"
            },
            "ContractStatus.prodMgr": {
                "hu": "A start form már jóváhagyásra vár",
                "en": "Start form is waiting for approval"
            },
            "userSvc.isProdMgr": {
                "hu": "Jóváhagyásra küldöd a start formot",
                "en": "Sending start form for approval"
            },
            "Approved_readyToSign_signed": {
                "hu": "Gyv-höz jóváhagyásra küldöd a start formot",
                "en": "Sending start form to Prod Mgr for approval"
            },
            "norights": {
                "hu": "Nincs jogosultságod a start form visszaküldéséhez",
                "en": "You are not allowed to send back start form",
            },
            "ContractStatus.crewMember.sendToDeptHeadFirst": {
                "hu": "Először a részlegvezetőnek kell küldened",
                "en": "You have to send it to the department head first",
            },
            "ContractStatus.deptHead.noDeptHeadSendToProdMgrDirectly": {
                "hu": "Nincs már részlegvezető, ezért beküldheted a produkciónak",
                "en": "No department head, send it to the production manager",
            },
            "ContractStatus.deptHead.sendToProdMgr": {
                "hu": "Beküldöd a produkciónak",
                "en": "Send it to the production manager",
            },
            "ContractStatus.deptHead.notDeptHead": {
                "hu": "Még a részlegvezető jóváhagyására vár",
                "en": "Still waiting for approval by department head",
            },
        },
        "canApprove": {
            "contract-not submitted": {
                "hu": "Jóváhagyás előtt be kell küldeni",
                "en": "Please submit it before approving it"
            },
            "debug": {
                "hu": "Debug üzemmódban vagy",
                "en": "You are in debug mode"
            },
            "nocontract": {
                "hu": "Nincs létrehozva a start form",
                "en": "Start form not created yet"
            },
            "saving": {
                "hu": "Mentés folyamatban...",
                "en": "Saving in progress..."
            },
            "ContractStatus.prodMgrApproved": {
                "hu": "A start form már jóváhagyott státuszban van",
                "en": "Start form already approved"
            },
            "ContractStatus.financeApproved": {
                "hu": "Már jóváhagytad. Most a pénzügynek kell jóváhagynia",
                "en": "You already approved it. It is Finance's turn to approve it"
            },
            "isOwnContract": {
                "hu": "Saját start formnak jóváhagyott státuszt adsz",
                "en": "You give approved status to your own start form"
            },
            "ContractStatus.crewMember": {
                "hu": "Javításra váró start form nem hagyható jóvá",
                "en": "You can not approve a start form that is waiting for correction"
            },
            "approve": {
                "hu": "Jóváhagyod a start formot",
                "en": "Approve start form"
            },
            "ContractStatus.signed": {
                "hu": "Jóváhagyott státuszba teszed a start formot",
                "en": "Set start form to approved"
            },
            "disabled": {
                "hu": "Csak gyártásvezető hagyhat jóvá start formot",
                "en": "Only Prod. Mgr. can approve start form"
            }
        },
        "canFinanceApprove": {
            "ContractStatus.canApprove": {
                "hu": "Jóváhagyod a start formot",
                "en": "You approve the start form"
            },
            "ContractStatus.cannotApprove": {
                "hu": "A gyártásvezető még nem hagyta jóvá a start formot",
                "en": "The Prod Mgr has not approve the start form yet"
            },
            "norights": {
                "hu": "Nincs jogosultságod a start form pénzügyi jóváhagyásához",
                "en": "You are not allowed to finance-approve the start form",
            },
        },
        "canSendBackToCrew": {
            "debug": {
                "hu": "DEBUG mód aktív!!!",
                "en": "DEBUG mode is active!!!"
            },
            "ContractStatus.deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "saving": {
                "hu": "Mentés folyamatban...",
                "en": "Saving in progress..."
            },
            "ContractStatus.crewMember": {
                "hu": "Már ebben a státuszban van",
                "en": "It is already in this status"
            },
            "userSvc.isProdMgr": {
                "hu": "A stábtag számára szerkeszthetővé teszi a start form adatait",
                "en": "Enable start form modification for crew member"
            },
            "userSvc.isDeptHead": {
                "hu": "A stábtag számára szerkeszthetővé teszi a start form adatait",
                "en": "Enable start form modification for crew member"
            },
            "disabled": {
                "hu": "Csak gyártásvezető küldhet vissza start formot",
                "en": "Only Product Manager can send back start forms"
            }
        },
        "canSetReadyToSign": {
            "debug": {
                "hu": "DEBUG mód aktív!!!",
                "en": "DEBUG mode is active!!!"
            },
            "ContractStatus.deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "saving": {
                "hu": "Mentés folyamatban...",
                "en": "Saving in progress..."
            },
            "ContractStatus.crewMember": {
                "hu": "Még a stábtag szerkeszti",
                "en": "Still edited by crew member"
            },
            "ContractStatus.prodMgr": {
                "hu": "Még a gyártásvezető jóváhagyására vár",
                "en": "Still waiting for approval by Production Manager"
            },
            "ContractStatus.prodMgrApproved": {
                "hu": "Még a pénzügy jóváhagyására vár",
                "en": "Still awaiting finance's approval"
            },
            "ContractStatus.readyToSign": {
                "hu": "Már ebben a státuszban van",
                "en": "Already in this status"
            },
            "userSvc.isFinance": {
                "hu": "A start form aláírásra kész státuszba kerül",
                "en": "The start form status will be 'ready to be signed'"
            },
            "disabled": {
                "hu": "Csak a pénzügy teheti aláírásra kész státuszba a start formot",
                "en": "Only the Finance can set the start form status to ready to sign"
            }
        },
        "canSetSigned": {
            "debug": {
                "hu": "DEBUG mód aktív!!!",
                "en": "DEBUG mode is active!!!"
            },
            "ContractStatus.deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "saving": {
                "hu": "Mentés folyamatban...",
                "en": "Saving in progress..."
            },
            "ContractStatus.crewMember": {
                "hu": "Még a stábtag szerkeszti",
                "en": "Still edited by crew member"
            },
            "ContractStatus.prodMgr": {
                "hu": "Még a gyártásvezető jóváhagyására vár",
                "en": "Waiting for Product Manager approval"
            },
            "ContractStatus.prodMgrApproved": {
                "hu": "Még a pénzügy jóváhagyására vár",
                "en": "Still awaiting finance's approval"
            },
            "ContractStatus.signed": {
                "hu": "Már ebben a státuszban van",
                "en": "Already in this status"
            },
            "userSvc.isFinance": {
                "hu": "A start form aláírva státuszba kerül",
                "en": "The start form status will be signed"
            },
            "disabled": {
                "hu": "Csak a pénzügy teheti aláírt státuszba start formot",
                "en": "Only Finance can set the start form status to signed"
            }
        },
        "canDelete": {
            "debug": {
                "hu": "DEBUG mód aktív!!!",
                "en": "DEBUG mode is active!!!"
            },
            "ContractStatus.deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "saving": {
                "hu": "Mentés folyamatban...",
                "en": "Saving in progress..."
            },
            "otherwise": {
                "hu": "Véglegesen törli a start formot. Nem visszavonható",
                "en": "Delete start form. This can not be undone"
            }
        },
        "canDisable": {
            "debug": {
                "hu": "DEBUG mód aktív!!!",
                "en": "DEBUG mode is active!!!"
            },
            "ContractStatus.deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "saving": {
                "hu": "Mentés folyamatban...",
                "en": "Saving in progress..."
            },
            "ContractStatus.disabled": {
                "hu": "Már ebben a státuszban van",
                "en": "Already in this status"
            },
            "archive": {
                "hu": "Archiválja a start formot, hogy ne lehessen hozzá új Timesheetet beküldeni",
                "en": "Archive start form. Submitting new Timesheet will be disabled"
            }
        },
        "defaultQuestionGroupName": {
            "constant": {
                "hu": "Alapértelmezett",
                "en": "Default"
            }
        },
        "contractQuestionGroups": {
            "questionGroupName": {
                "hu": "Magyar cég",
                "en": "Hungarian company"
            },
            "tmp_valasz1": {
                "hu": "Magyar cég - 1. allehetőség",
                "en": "Hungarian company - 1. suboption"
            },
            "tmp_valasz2": {
                "hu": "Magyar cég - 2. allehetőség",
                "en": "Hungarian company - 2. suboption"
            }
        }
    },
    "user.service": {
        "handleUser": {
            "saveerror": {
                "hu": "Az adatbázis nem adott vissza felhasználót mentés után",
                "en": "The database did not return a user after saving"
            }
        }
    },
    "doc.service": {
        "generateContract": {
            "hNotifGenerate": {
                "hu": "Generálás folyamatban. Ez eltarthat egy ideig...",
                "en": "Generating in progress. This could take a while..."
            },
            "genReady": {
                "hu": "Generálás kész",
                "en": "Generation finished"
            },
            "unknownerror": {
                "hu": "Ismeretlen hiba történt a szerződés generálása közben",
                "en": "Unknown error while generating contract"
            },
            "genError": {
                "hu": "A generálás nem sikerült. Hiba történt",
                "en": "Generation failed due to an error"
            }
        },
        "validateFormForContractGeneration": {
            "undefined": {
                "hu": "Ismeretlen szerződés vagy szerződés azonosító: undefined vagy null",
                "en": "Unknown contract or contract id: undefined or null"
            },
            "notemplate": {
                "hu": "Nincs megadva, hogy melyik szerződés sablont kell használni",
                "en": "No contract template is specified to be used"
            }
        },
        "removeContractFile": {
            "unabletoremove": {
                "hu": "Nem lehet törölni: Ismeretlen fájl.",
                "en": "Unable to remove: unknown file"
            },
            "setRemovingDocError": {
                "hu": "A fájl eltávolítása sikerült, de az adatbázist nem sikerült frissíteni",
                "en": "File successfully removed, but refreshing the database failed"
            },
            "setRemovingDocErrorFile": {
                "hu": "Nem sikerült eltávolítani a fájlt",
                "en": "Unable to remove file"
            }
        },
        "canGenerateContractPdf": {
            "debug": {
                "hu": "DEBUG mód aktív!!!",
                "en": "DEBUG mode active!!!"
            },
            "nullcontract": {
                "hu": "Szerződés null vagy ismeretlen szerződés azonosító",
                "en": "Contract is null or unknown contract id"
            },
            "ContractStatus.deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "geninprogress": {
                "hu": "A generálás folyamatban van",
                "en": "Generating in progress"
            },
            "deleteinprogress": {
                "hu": "A fájl törlés folyamatban van",
                "en": "File deleting in progress"
            },
            "notapproved": {
                "hu": "A gyártásvezető még nem hagyta jóvá",
                "en": "Prod. Mgr. not approved yet"
            },
            "notfinanceapproved": {
                "hu": "A pénzügy még nem hagyta jóvá",
                "en": "Finance not approved yet"
            },
            "contracttogoogle": {
                "hu": "Ezzel szerződést generálsz a Google Drive-ra",
                "en": "This will generate a contract to Google Drive"
            },
            "crewdidnotsend": {
                "hu": "A stábtag még nem küldte be az adatait a produkciónak",
                "en": "Crew did not send details to Production"
            }
        },
        "generateTSDoc": {
            "notsselected": {
                "hu": "Nincs kiválasztva egyetlen timesheet sem",
                "en": "No timesheet selected"
            },
            "missingdate": {
                "hu": "Hiányzik a kezdő vagy a vége dátum",
                "en": "Start or End date is missing"
            },
            "gentsagain": {
                "hu": "Van olyan timesheet, amelyet már legeneráltál. Biztos, hogy újabb számlamellékletet generálsz?",
                "en": "An invoice attachment is already generated for one or more generated timesheets. Are you sure you want to generate a new invoice attachment?"
            },
            "geninprogress": {
                "hu": "Generálás folyamatban. Ez több percig is eltarthat...",
                "en": "Generating in progress. This could take several minutes..."
            },
            "nodocid": {
                "hu": "Nem kaptuk vissza a legenerált számla melléklet doc ID-ját",
                "en": "Generated Timesheet doc ID is missing"
            },
            "generationok": {
                "hu": "Generálás kész",
                "en": "Generation finished"
            },
            "unknowngenerror": {
                "hu": "Ismeretlen hiba történt a szerződés generálása közben",
                "en": "Unknown error occured during contract generation"
            },
            "unabletogen": {
                "hu": "A generálás nem sikerült. Hiba történt",
                "en": "Generation failed due to an error"
            }
        },
        "canGenerateTSPdf": {
            "isCurrencyMismatch": {
                "hu": "Egynél több pénznem nem szerepelhet egy számlán",
                "en": "More than one currency"
            },
            "debug": {
                "hu": "DEBUG mód aktív!!!",
                "en": "DEBUG mode is active!!!"
            },
            "notsselected": {
                "hu": "Nincs kiválasztva egyetlen timesheet sem",
                "en": "No Timesheet selected"
            },
            "nullcontract": {
                "hu": "Szerződés null vagy ismeretlen szerződés azonosító",
                "en": "Contract is null or unknown contract id"
            },
            "nullcrewmember": {
                "hu": "Stábtag null vagy ismeretlen stábtag azonosító",
                "en": "Crew member is null or unknown crew member id"
            },
            "deleted": {
                "hu": "Már törölve lett",
                "en": "Already deleted"
            },
            "genprogress": {
                "hu": "A generálás folyamatban van",
                "en": "Generating in progress"
            },
            "delprogress": {
                "hu": "A fájl törlés folyamatban van",
                "en": "File deleting in progress"
            },
            "contracttogoogle": {
                "hu": "Ezzel WTS-t generálsz a Google Drive-ra",
                "en": "This will generate a Weekly TS to Google Drive"
            }
        },
        "removeTSFile": {
            "fileunknown": {
                "hu": "Nem lehet törölni: Ismeretlen fájl.",
                "en": "Unable to delete: unknown file."
            },
            "filedelok": {
                "hu": "A fájl eltávolítása sikerült, de az adatbázis nem sikerült frissíteni",
                "en": "File successfully removed, but refreshing the database failed"
            },
            "filedelerror": {
                "hu": "Nem sikerült eltávolítani a fájlt",
                "en": "Unable to remove file"
            }
        },
        "getDriveLink": {
            "unknownfile": {
                "hu": "/error/Ismeretlen Google Drive fájl",
                "en": "/error/Unknown Google Drive file"
            },
            "noerror": {
                "hu": "Nincs hiba",
                "en": "No error"
            }
        }
    },
    "app.service": {
        "initdata": {
            "loaderror": {
                "hu": "App alapadatbetöltés hiba",
                "en": "App data loading error"
            },
            "loaderror2": {
                "hu": "App alapadatbetöltés hiba",
                "en": "App data loading error"
            }
        }
    },
    "dept.service": {
        "constructor": {
            "routerror": {
                "hu": "App alapadatbetöltés hiba",
                "en": "App data loading error"
            }
        },
        "handleDept": {
            "saveerror": {
                "hu": "A mentés nem adott vissza részleget, ezért lehet, hogy nem mentődött el",
                "en": "Saving did not return any department so it is possibly not saved correctly"
            }
        }
    },
    "timesheet.service": {
        "getStatusName": {
            "undefined": {
                "hu": "Nincs beküldve",
                "en": "Not submitted"
            },
            "TimesheetStatus.disabled": {
                "hu": "Letiltva",
                "en": "Disabled"
            },
            "TimesheetStatus.deleted": {
                "hu": "Törölve",
                "en": "Deleted"
            },
            "TimesheetStatus.atCrewMember": {
                "hu": "Beküldésre vár",
                "en": "Waiting to be submitted"
            },
            "TimesheetStatus.atProdMgr": {
                "hu": "Jóváhagyásra vár",
                "en": "Waiting for approval"
            },
            "TimesheetStatus.approved": {
                "hu": "Jóváhagyva",
                "en": "Approved"
            },
            "TimesheetStatus.attGenerating": {
                "hu": "Számlamelléklet generálása folyamatban...",
                "en": "Generating invoice attachment..."
            },
            "TimesheetStatus.attSent": {
                "hu": "Számlamelléklet kiküldve",
                "en": "Invoice attachment sent"
            },
            "TimesheetStatus.invAccepted": {
                "hu": "Számla befogadva",
                "en": "Invoice accepted"
            },
            "default": {
                "hu": "Ismeretlen státusz",
                "en": "Unknown status"
            }
        },
        "canApprove": {
            "notsid": {
                "hu": "Nincs elmentve vagy ismeretlen timesheet",
                "en": "Not saved or unknown Timesheet"
            },
            "deleted": {
                "hu": "Törölve van",
                "en": "Deleted"
            },
            "disabled": {
                "hu": "A Timesheet le van tiltva",
                "en": "Timesheet is disabled"
            },
            "approve": {
                "hu": "Jóváhagyás",
                "en": "Approve"
            },
            "approved": {
                "hu": "Már jóvá van hagyva",
                "en": "Already approved"
            },
            "approvetimeandsum": {
                "hu": "Idők és összegek jóváhagyása",
                "en": "Approval of times and amounts"
            },
            "resetapprove": {
                "hu": "Státusz visszaállítása jóváhagyottra",
                "en": "Reset status to approved"
            },
            "norights": {
                "hu": "Nincs jogosultságod a Timesheet jóváhagyásához",
                "en": "Insufficient rights to approve Timesheet"
            },
            "atCrewMember": {
                "hu": "Már vissza van küldve",
                "en": "Already sent back to crew"
            }
        },
        "canSendBackToCrew": {
            "notsaved": {
                "hu": "Nincs elmentve vagy ismeretlen timesheet",
                "en": "Not saved or unknown Timesheet"
            },
            "deleted": {
                "hu": "Törölve van",
                "en": "Already deleted"
            },
            "disabled": {
                "hu": "A Timesheet le van tiltva",
                "en": "Timesheet is disabled"
            },
            "atCrewMember": {
                "hu": "Már vissza van küldve",
                "en": "Already returned to crew member"
            },
            "isProdMgr": {
                "hu": "A Timesheet visszaküldése a stábtagnak javításra",
                "en": "Send back Timesheet for correction to crew member"
            },
            "isDeptHead": {
                "hu": "A Timesheet visszaküldése a stábtagnak javításra",
                "en": "Send back Timesheet for correction to crew member"
            },
            "norights": {
                "hu": "Nincs jogosultságod a Timesheet visszaküldéséhez",
                "en": "Insufficient rights to send back Timesheet"
            }
        },
        "canRetractApproval": {
            "notsaved": {
                "hu": "Nincs elmentve vagy ismeretlen timesheet",
                "en": "Not saved or unknown Timesheet"
            },
            "deleted": {
                "hu": "Törölve van",
                "en": "Already deleted"
            },
            "disabled": {
                "hu": "A Timesheet le van tiltva",
                "en": "Timesheet is disabled"
            },
            "atCrewMember": {
                "hu": "Már vissza van küldve stábtagnak",
                "en": "Already sent back to Crew member"
            },
            "atProdMgr": {
                "hu": "Nincs még jóváhagyva, vagy a jóváhagyás vissza lett vonva",
                "en": "Not approved or approval retracted"
            },
            "retractapp": {
                "hu": "A Timesheet jóváhagyásának visszavonása",
                "en": "Retract Timesheet approval"
            },
            "norights": {
                "hu": "Nincs jogosultságod a Timesheet jóváhagyásának visszavonásához",
                "en": "Insufficient rights to retract Timesheet approval"
            }
        },
        "canSetPdfSent": {
            "notsaved": {
                "hu": "Nincs elmentve vagy ismeretlen timesheet",
                "en": "Not saved or unknown Timesheet"
            },
            "deleted": {
                "hu": "Törölve van",
                "en": "Already deleted"
            },
            "disabled": {
                "hu": "A Timesheet le van tiltva",
                "en": "Timesheet is disabled"
            },
            "notapproved": {
                "hu": "Még nincs jóváhagyva",
                "en": "Not yet approved"
            },
            "alreadystatus": {
                "hu": "Már ebben a státuszban van",
                "en": "It is already in this status"
            },
            "tssentemail": {
                "hu": "A Timesheetet kiküldted emailben",
                "en": "Timesheet sent via email"
            },
            "norights": {
                "hu": "Nincs jogosultságod a Timesheet kiküldéséhez",
                "en": "Insufficient rights to send Timesheet"
            }
        },
        "canSetInvoiceAccepted": {
            "notsaved": {
                "hu": "Nincs elmentve vagy ismeretlen timesheet",
                "en": "Not saved or unknown Timesheet"
            },
            "deleted": {
                "hu": "Törölve van",
                "en": "Already deleted"
            },
            "disabled": {
                "hu": "A Timesheet le van tiltva",
                "en": "Timesheet is disabled"
            },
            "atProdMgr": {
                "hu": "Még nincs jóváhagyva",
                "en": "Not approved"
            },
            "invAccepted": {
                "hu": "A számla már be lett fogadva",
                "en": "Invoice already accepted"
            },
            "invfinaccepted": {
                "hu": "A számla be lett fogadva",
                "en": "Invoice accepted"
            },
            "norights": {
                "hu": "Nincs jogosultságod a számla jóváhagyásához",
                "en": "Insufficient rights to approve invoice"
            }
        },
        "canRemoveTs": {
            "notsaved": {
                "hu": "Nincs elmentve vagy ismeretlen timesheet",
                "en": "Not saved or unknown Timesheet"
            },
            "deleted": {
                "hu": "Már törölve van",
                "en": "Already deleted"
            },
            "invAccepted": {
                "hu": "A számla már be lett fogadva",
                "en": "Invoce already accepted"
            },
            "inventerederror": {
                "hu": "A Timesheet tévesen lett bevive",
                "en": "Timesheet entered incorrectly"
            },
            "norights": {
                "hu": "Nincs jogosultságod a Timesheet törléséhez",
                "en": "Insufficient rights to delete Timesheet"
            }
        }
    },

}
