import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import { AppService, ButtonStatus, DbCallError } from '../../app.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StateService } from '../../state.service';
import { ProductionService } from '../../prod/production.service';
import { ListTimesheetsParams, TimesheetParsed, TimesheetWeekly } from '../../models/timesheet';
import { DocService, GenInvRepGroupBy } from '../../doc.service';
import { NotificationService } from '../../util/notifications/notification.service';
import { T7eService } from '../../t7e/t7e.service';
import { SQL_TIMEZONE, SQL_DATE_FORMAT, SQL_DATETIME_FORMAT } from '../../parser.service';
import * as moment from 'moment-timezone';
import { DataService } from '../../data.service';
import { TimesheetService } from '../../ts/timesheet.service';
import { UserService } from '../../user.service';
import { PropItem } from '../../models/table-property';

@Component({
  selector: 'app-my-wts',
  templateUrl: './my-wts.component.html',
  styleUrls: ['./my-wts.component.scss']
})
export class MyWtsComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  draft$ = new BehaviorSubject<string | null>(null)
  isDataLoaded = false
  isDataLoading = false
  form: FormGroup


  constructor(
    private appSvc: AppService,
    private fb: FormBuilder,
    private stateSvc: StateService,
    private prodSvc: ProductionService,
    private docSvc: DocService,
    private dataSvc: DataService,
    private tsSvc: TimesheetService,
    private userSvc: UserService,
    private notifSvc: NotificationService,
    private t7e: T7eService,
  ) { 
    this.appSvc.pageTitle$.next('WTS')
    this.generatePdf = this.generatePdf.bind(this)

    this.form = this.getFG()

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  get fromFC() { return this.form.get('from') as FormControl }
  get toFC() { return this.form.get('to') as FormControl }
  get minDate() { return this.prodSvc.prodDefaults$.value?.dPreparationstartday || new Date(2000, 0, 1) }
  get maxDate() { return new Date() }

  draftGenerated(html: string | null): void {
    this.draft$.next(html)
  }
  closeDraft(e?: Event): void {
    e?.preventDefault()
    this.draft$.next(null)
  }

  canGeneratePdf(): ButtonStatus {
    if (this.form.invalid) return { disabled: true, title: 'A dátumok megadása kötelező' }
    return { disabled: false, title: 'Lekérdezés' }
  }

  async reload(): Promise<void> {
    this.isDataLoaded = false
    this.isDataLoading = true
    if (!this.canGeneratePdf()) return
    const usid = this.userSvc.loggedinUserId
    if (!usid) return
    const params = this.getSPParams({ usid })
    if (!params._startdate || !params._enddate) return

    this.dataSvc.listTimesheets(params)
      .pipe(
        takeUntil(this._destroy$),
        map(data => data
          ?.map(this.tsSvc.parseTs)
          ?.sort((a, b) => (b.tsid || -1) - (a.tsid || 1))
          || []
        ),
      )
      .subscribe({
        next: tsList => {
          const tsWeekly = this.getTsWWeekly(tsList)
          if (!tsWeekly) {
            this.isDataLoading = false
            this.isDataLoaded = true
            return
          }
          this.generatePdf([tsWeekly], false, 'user', true)
        },
        error: err => {
          this.isDataLoading = false
          const msg = this.t7eErrorGeneratingWts
          this.notifSvc.addObservableNotif({ msg, class: 'danger' })
          console.error('listTs error', err)
        }
      })

  }

  private getTsWWeekly(tsList?: TimesheetParsed[] | null): TimesheetWeekly | null {
    if (!tsList) return null
    const f = this.form.getRawValue()
    if (!f.from || !f.to) return null
    const startDate = new Date(f.from),
      endDate = this.appSvc.getEndOfDay(f.to),
      contractType = f.contractType as PropItem,
      groupBy = 'user'

    const tsWeekly = this.tsSvc.getTsWeekly(tsList, groupBy, startDate, endDate, contractType?.picode || null)
    return tsWeekly.find(tsw => tsw.usid == this.userSvc.loggedinUserId) || null
  }



  generatePdf(
    dataItem: TimesheetWeekly[],
    sendEmail: boolean,
    groupBy: GenInvRepGroupBy,
    isDraft?: boolean,
    docId?: string | null,
    savePdf?: boolean,
  ) {
    const response = this.docSvc.generateTSDoc(dataItem, sendEmail, groupBy, isDraft, docId, savePdf)
    if (!response) {
      this.isDataLoaded = false
      this.isDataLoading = false
      return
    }
    response.subscribe({
        next: res => {
          console.log('generateTSDoc válasz', res);
          this.draft$.next(res?.html || null)
          this.isDataLoaded = true
          this.isDataLoading = false
        },
        error: err => {
          this.isDataLoading = false
          this.isDataLoaded = true
          console.error('generateTSDoc error', err);
          this.notifSvc.addObservableNotif({ msg: this.t7eErrorGeneratingWts, class: 'danger', duration: 2000 })
        }
      })
  }

  private getSPParams({ usid }: { usid?: number } = {}): ListTimesheetsParams {
    const f = this.form.value
    const retVal: ListTimesheetsParams = {
      _conid: f.contract?.conid || null,
      _depid: f.dept?.depid || null,
      _usid: usid || f.user?.usid || null,
      _tsstatus: f.status?.map((s: any) => s.id) || null,
      _startdate: f.from && moment(f.from).tz(SQL_TIMEZONE).format(SQL_DATE_FORMAT),
      _enddate: f.to && moment(this.appSvc.getEndOfDay(f.to)).tz(SQL_TIMEZONE).format(SQL_DATETIME_FORMAT),
      _contracttype: f.contractType?.picode || null,
    }
    if (!retVal._tsstatus?.length) retVal._tsstatus = null
    return retVal
  }

  private getFG(): FormGroup {
    return this.fb.group({
      from: [this.filterSettings?.from || null, Validators.required],
      to: [this.filterSettings?.to || null, Validators.required],
    })
  }


  /** USER PREFERENCES */

  get filterSettings() { return this.stateSvc.genInvReportPageState$?.value?.filters }

  comp = 'my-wts'
  t7eErrorGeneratingWts = this.t7e.getTranslation(this.comp, 'error-generating-wts', 'innerText', null, null, 'Hiba történt a számlamelléklet generálása közben')
}
