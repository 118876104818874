import { Moment } from "moment-timezone"
import { ProcRequestParams, SqlDataRow } from "../data.service"
import { GenInvRepGroupBy } from "../doc.service"
import { DocLink } from "./contract"

export interface Timesheet extends SqlDataRow {
    tsid?: number | null
    conid?: number | null
    entryon?: string | null
    email?: string | null
    usname?: string | null
    depname?: string | null
    depid?: number | null
    role?: string | null
    tsstatus?: number | null
    savecomment?: string | null
    startdate?: string | null
    enddate?: string | null
    currency?: string | null
    overtimefee?: number | null
    tafee?: number | null
    salary?: number | null
    calc_salary?: number | null
    calc_perdiem?: number | null
    calc_overtimefee?: number | null
    calc_tafee?: number | null
    calc_othours?: number | null
    calc_tahours?: number | null
    usid?: number | null
    comment?: string | null
    park?: number | null
    parkcomment?: string | null
    distandpark?: number | null
    distandparkcomment?: string | null
    vignette?: number | null
    vignettecomment?: string | null
    otherfee?: number | null
    dayoptions?: number[] | null
    modusid?: number | null
    doclink?: string | null

    f_dailybasedrentalrate1?: number | null
    f_dailybasedrentalrate2?: number | null
    f_dailybasedrentalrate3?: number | null
    f_dailybasedrentalrate4?: number | null
    f_dailybasedrentalrate5?: number | null
    f_dailybasedrentalrate6?: number | null

    /** Meal Penalty összege */
    f_mealPenalty?: number | null
    /** Meal Penalty hossza */
    f_mealPenaltyHours?: number | null

    shstartdate?: string | null // crew call, ha meg van adva
    shenddate?: string | null // camera wrap, ha meg van adva
    shlocpiid?: string | null // dispo szerinti location piid, ha meg van adva

    /** A user összes timesheetjeinek a száma */
    tscount?: number | null
}    

export interface TimesheetParsed extends Timesheet {
    arrDoclink?: DocLink[] | null
    dDate?: Date | null // 00:00:00 végű
    dStartDate?: Date | null
    dEndDate?: Date | null
    workhours?: number | null
    overtimestep?: number | null
    shortresthours?: number | null
    longresthours?: number | null
}

// todo nem kell extends TimesheetParsed
export interface TimesheetWeekly extends TimesheetParsed {

    /** Weekly ts period start date */
    startDate: Date

    
    /** Weekly ts period end date */
    endDate: Date
  
    /** List of timesheet ids for the selected period */
    tsids: number[]
    tsList: TimesheetParsed[]

    /** Separate timesheets by contract or by user */
    groupBy: GenInvRepGroupBy

    isApproved: YesNoPartial
    isGenerating: boolean
    isSent: YesNoPartial
    isInvAccepted: YesNoPartial
    isCurrencyMismatch: boolean

    arrDoclink: DocLink[]

    draftHtml?: string
}
  
export interface TimesheetBase {
    tsid: number | null
    conid: number | null
    entryon: string | null
    email: string | null
    usname: string | null
    famname: string | null
    depname: string | null
    depid: number | null
    role: string | null
    tsstatus: number | null
    startdate: string | null
    enddate: string | null
    salary: number | null
    overtimefee: number | null
    tafee: number | null
    usid: number | null
    perdiem: number | null
    mealpenalty: number | null
    mealpenaltyhours: number | null
    otherfee: number | null
    vignette: number | null
    gas: number | null
    park: number | null
    dailyrentalrate1: number | null
    dailyrentalrate2: number | null
    dailyrentalrate3: number | null
    dailyrentalrate4: number | null
    dailyrentalrate5: number | null
    dailyrentalrate6: number | null
    dailyrentalrate1name: string | null
    dailyrentalrate2name: string | null
    dailyrentalrate3name: string | null
    dailyrentalrate4name: string | null
    dailyrentalrate5name: string | null
    dailyrentalrate6name: string | null
    monthlyrentalrate1: number | null
    monthlyrentalrate2: number | null
    monthlyrentalrate3: number | null
    monthlyrentalrate1name: string | null
    monthlyrentalrate2name: string | null
    monthlyrentalrate3name: string | null
}
  
export interface TimesheetBaseParsed extends TimesheetBase {
    dDate: Date | null
    currency: string | null
}

export type YesNoPartial = 'yes' | 'no' | 'partially'
  

export interface CheckTimesheetResponse extends SqlDataRow {
    sametsid: number | null
    overlaptsid: number | null
    prev: {
        tsid: number | null
        enddate: string | null
        hours: number | null
    }
    next: {
        tsid: number | null
        startdate: string | null
        hours: number | null
    }
    daytype: 'holy' | null
}

export interface CalculatedFeesForDay extends SqlDataRow {
    calculatedSalary: number | null
    calculatedOtFee: number | null
    calculatedTsFee: number | null
    calculatedOtHours: number | null
    calculatedTaHours: number | null
}

export interface RegisterTsStatusResponse extends SqlDataRow {
    result: 'OK' | string
}

export interface ListTimesheetsParams extends ProcRequestParams {
    _tsid?: number | null       // integer DEFAULT NULL:: integer,
    _conid?: number | null      // integer DEFAULT NULL:: integer,
    _usid?: number | null       // integer DEFAULT NULL:: integer,
    _depid?: number | null      // integer DEFAULT NULL:: integer,
    _usname?: string | null     // character varying DEFAULT NULL::character varying,
    _prid?: number | null       // integer DEFAULT 1,
    _tsstatus?: number[] | null       // integer[] DEFAULT NULL:: integer[],
    _startdate?: string | null      // timestamp without time zone DEFAULT NULL::timestamp without time zone,
    _enddate?: string | null        // timestamp without time zone DEFAULT NULL::timestamp without time zone,
}

export interface HandleTimesheetsParams extends ProcRequestParams {
    _tsid?: number | null                   // integer DEFAULT NULL:: integer,
    _conid?: number | null                  // integer DEFAULT NULL:: integer,
    _startdate?: string | null              // timestamp with time zone DEFAULT NULL::timestamp with time zone,
    _enddate?: string | null                // timestamp with time zone DEFAULT NULL::timestamp with time zone,
    _comment?: string | null                // character varying DEFAULT NULL::character varying,
    _distandpark?: number | null            // numeric DEFAULT NULL:: numeric,
    _distandparkcomment?: string | null     // character varying DEFAULT NULL::character varying,
    _park?: number | null                   // numeric DEFAULT NULL:: numeric,
    _parkcomment?: string | null            // character varying DEFAULT NULL::character varying,
    _vignette?: number | null               // numeric DEFAULT NULL:: numeric,
    _vignettecomment?: string | null        // character varying DEFAULT NULL::character varying,
    _tsstatus?: number | null               // integer DEFAULT NULL:: integer,
    _dayoptions?: number[] | {} | null      // integer[] DEFAULT NULL:: integer[],
    _data?: string | null                   // json DEFAULT NULL:: json,
    _otherfee?: number | null               // numeric DEFAULT NULL:: numeric,
    _othours?: number | null                // numeric DEFAULT NULL:: numeric,
    _overtimefee?: number | null            // numeric DEFAULT NULL:: numeric,
    _tahours?: number | null                // numeric DEFAULT NULL:: numeric,
    _tafee?: number | null                  // numeric DEFAULT NULL:: numeric,
    _savecomment?: string | null            // character varying DEFAULT NULL::character varying,
}

export interface CheckTimesheetParams extends ProcRequestParams {
    _conid?: number | null                  // integer DEFAULT NULL:: integer,
    _startdate?: string | null              // timestamp with time zone DEFAULT NULL::timestamp with time zone,
    _enddate?: string | null                // timestamp with time zone DEFAULT NULL::timestamp with time zone,
}

export interface CalcSalaryParams {
    contractId: number,
    startDate: Date | Moment,
    endDate: Date | Moment,
    dayOptions: DayOptions[],
    workingHours: number,
    otRates: number[],
    otStep: number,
    gracePeriod: number,
}

export interface PrevPeriodInvoices {
    statusName: 'loading' | 'Elmaradt számla' | 'Nincs elmaradt számla' | 'Betöltés hiba'
    statusClass: 'loading' | 'success' | 'warning' | 'danger'
    /** Az eljárás válasza. Hiba esetén null */
    timesheets: ListNotInvoicedTs[] | null 
}

/**
 * register_timesheetstatus eljáráshoz
 */
export interface RegisterTsStatus extends ProcRequestParams {
    _tsids: string       // json,number[]
    _tsstatus: number    // integer,
    _enddate?: string | null     // timestamp
} 

export interface ListNotInvoicedTsParams extends ProcRequestParams {
    _startdate: string
    _contracttypes?: string[]
}

export interface ListNotInvoicedTs extends SqlDataRow {
    tsid: number;
    conid: number;
    usid?: number;
    usname: string;
    famname: string;
    tsstartdate: string; // Assuming the timestamp is represented as a string
}

export enum TimesheetStatus {
    disabled = -1,
    deleted = 0,
    atCrewMember = 61,
    atDeptHead = 59,
    deptHeadApproved = 60,
    atProdMgr = 62,
    approved = 63,
    attGenerating = 64,
    attSent = 65,
    invAccepted = 66,
}

export enum DayOptions {
    TravelDay = 1,
    PerDiem = 2,
    HalfDay = 3,
    MonthlyRentsDue = 4,
    MealPenalty = 5,
}